import React from "react";
import { Image } from "react-bootstrap";
import SpeedoMeterImage from "../../assets/img/pdftohtml/speedometer.svg";
import SpeedoMeterNidleImage from "../../assets/img/pdftohtml/speedometer_niddle.png";

const PDFCustomeSpeedometer = ({score}) => {
    const point_score = score;
    let rotateCss;

    if (point_score === 0) {
        rotateCss = "rotate(-139deg)";
    } else if (point_score > 0 && point_score < 10) {
        rotateCss = "rotate(-130deg)";
    } else if (point_score === 10) {
        rotateCss = "rotate(-125deg)";
    } else if (point_score > 10 && point_score < 20) {
        rotateCss = "rotate(-117deg)";
    } else if (point_score === 20) {
        rotateCss = "rotate(-111deg)";
    } else if (point_score > 20 && point_score < 30) {
        rotateCss = "rotate(-105deg)";
    } else if (point_score === 30) {
        rotateCss = "rotate(-98deg)";
    } else if (point_score > 30 && point_score < 40) {
        rotateCss = "rotate(-92deg)";
    } else if (point_score === 40) {
        rotateCss = "rotate(-84deg)";
    } else if (point_score > 40 && point_score < 45) {
        rotateCss = "rotate(-74deg)";
    } else if (point_score === 45) {
        rotateCss = "rotate(-69deg)";
    } else if (point_score > 45 && point_score < 50) {
        rotateCss = "rotate(-61deg)";
    } else if (point_score === 50) {
        rotateCss = "rotate(-54deg)";
    } else if (point_score > 50 && point_score < 55) {
        rotateCss = "rotate(-47deg)";
    } else if (point_score === 55) {
        rotateCss = "rotate(-39deg)";
    } else if (point_score > 55 && point_score < 60) {
        rotateCss = "rotate(-32deg)";
    } else if (point_score === 60) {
        rotateCss = "rotate(-24deg)";
    } else if (point_score > 60 && point_score < 65) {
        rotateCss = "rotate(-15deg)";
    } else if (point_score === 65) {
        rotateCss = "rotate(-8deg)";
    } else if (point_score > 65 && point_score < 70) {
        rotateCss = "rotate(0deg)";
    } else if (point_score === 70) {
        rotateCss = "rotate(7deg)";
    } else if (point_score > 70 && point_score < 75) {
        rotateCss = "rotate(16deg)";
    } else if (point_score === 75) {
        rotateCss = "rotate(23deg)";
    } else if (point_score > 75 && point_score < 80) {
        rotateCss = "rotate(31deg)";
    } else if (point_score === 80) {
        rotateCss = "rotate(38deg)";
    } else if (point_score > 80 && point_score < 85) {
        rotateCss = "rotate(48deg)";
    } else if (point_score === 85) {
        rotateCss = "rotate(55deg)";
    } else if (point_score > 85 && point_score < 90) {
        rotateCss = "rotate(62deg)";
    } else if (point_score === 90) {
        rotateCss = "rotate(70deg)";
    } else if (point_score > 90 && point_score < 95) {
        rotateCss = "rotate(78deg)";
    } else if (point_score === 95) {
        rotateCss = "rotate(85deg)";
    } else if (point_score > 95 && point_score < 100) {
        rotateCss = "rotate(93deg)";
    } else if (point_score === 100) {
        rotateCss = "rotate(103deg)";
    } else {
        rotateCss = "rotate(103deg)"; // Default value for scores out of expected range
    };

    return(<div className="speedometer_pdf_wraps_wrapper">
        <div className="speedometer_pdf_wraps">
            <Image src={SpeedoMeterImage} className="speedometer_img" alt="speedometer" />
            <Image src={SpeedoMeterNidleImage} className="speedometre_niddle" style={{ transform: rotateCss }} alt="nidle" />
            <h1>{point_score}%</h1>
            <h2>Bike Health Score</h2>
        </div>
    </div>)
}

export default PDFCustomeSpeedometer ;