/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { StartCommonLoader, StartMainCommonLoader, GetBikePurchaseDate } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { TYPES_OF_BICYCLE_URL } from "../Shared/constant";
import FranchiseHeader from "../Common/FranchiseHeader";

const BikePurchaseData = (props) => {
    const { setGetCurrentPath } = props;
    const pathname = useLocation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { ErrorMessageFromReddux, bikePurchaseData } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        Brand: "",
        BrandName: "",
        PurchasePrice: ""
    });
    
    const optionsBrand = [
        { key: 1, value: "Trek", label: "Trek"},
        { key: 2, value: "Specialized", label: "Specialized"},
        { key: 3, value: "Emotorad", label: "Emotorad"},
        { key: 4, value: "Leader", label: "Leader"},
        { key: 5, value: "Hero Lectro", label: "Hero Lectro"},
        { key: 6, value: "Montra", label: "Montra"},
        { key: 7, value: "Urban Terrain", label: "Urban terrain"},
        { key: 8, value: "Ninetyone", label: "Ninetyone"},
        { key: 9, value: "Fantom", label: "Fantom" },
        { key: 10, value: "Firefox", label: "Firefox" },
        { key: 11, value: "Decathlon", label: "Decathlon"},
        { key: 12, value: "Unbranded", label: "Unbranded"},
    ];

    // error state
    const [errorBrand, seterrorBrand] = useState("");
    const [errorBrandName, seterrorBrandName] = useState("");
    const [errorPurchasePrice, seterrorPurchasePrice] = useState("");
    
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    useEffect(() => {
        if(bikePurchaseData !== undefined) {
            if(Object?.keys(bikePurchaseData)?.length > 0) {
                setApplicationState({
                    ...ApplicationState,
                    Brand: bikePurchaseData?.Brand,
                    BrandName: bikePurchaseData?.BrandName,
                    PurchasePrice: bikePurchaseData?.PurchasePrice,
                });
            };
        };
    }, []);

    useEffect(() => {
        dispatch(StartCommonLoader(false));
        dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        ErrorMessageFromReddux.API=""
        setGetCurrentPath(pathname);
    }, [pathname]);

    // select user case
    const SelectBrand = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                Brand: value,
            });
            seterrorBrand("");
        } else {
            setApplicationState({
                ...ApplicationState,
                Brand: "",
            });
            seterrorBrand("Please select brand");
        }
    };

    var customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        })
    };

    // sign in function
    const SubmitRegister = () => {
        if(ApplicationState?.Brand?.value === "Unbranded") {
            if (ApplicationState?.Brand !== "" && ApplicationState?.BrandName !== "" && ApplicationState?.PurchasePrice !== "") {
                dispatch(GetBikePurchaseDate(ApplicationState));
                Navigate(TYPES_OF_BICYCLE_URL);
            } else {
                CheckValid(ApplicationState?.BrandName, { type: 'BrandName', error: seterrorBrandName });
                CheckValid(ApplicationState?.PurchasePrice, { type: 'PurchasePrice', error: seterrorPurchasePrice })
                if (ApplicationState?.Brand !== "") {
                    seterrorBrand("");
                } else {
                    seterrorBrand("Please select brand");
                };
            };
        } else {
            if (ApplicationState?.Brand !== ""  && ApplicationState?.PurchasePrice !== "") {
                dispatch(GetBikePurchaseDate(ApplicationState));
                Navigate(TYPES_OF_BICYCLE_URL);
            } else {
                CheckValid(ApplicationState?.PurchasePrice, { type: 'PurchasePrice', error: seterrorPurchasePrice })
                if (ApplicationState?.Brand !== "") {
                    seterrorBrand("");
                } else {
                    seterrorBrand("Please select brand");
                };
            };
        };
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct">
                    <h4>Bike Purchase Data</h4>
                    <div className="formloginset">
                        <Form.Group className="mb-3" >
                            <Form.Label>Brand*</Form.Label>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.Brand}
                                    onChange={(e) => SelectBrand(e)}
                                    isClearable
                                    options={optionsBrand}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Brand"
                                />
                            </div>
                            {errorBrand !== "" && <span className="error_medotory">{errorBrand}</span>}
                        </Form.Group>
                        {ApplicationState?.Brand?.value === "Unbranded" && (<Form.Group className="mb-3" >
                            <Form.Label>Brand Name*</Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.BrandName}
                                onChange={(e) => setApplicationState({ ...ApplicationState, BrandName: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'BrandName', error: seterrorBrandName })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Brand Name"
                            />
                            {errorBrandName !== "" && <span className="error_medotory">{errorBrandName}</span>}
                        </Form.Group>)}
                        <Form.Group className="mb-3" >
                            <Form.Label>Purchase Price *</Form.Label>
                            <Form.Control
                                type="number"
                                className="inputBoxs"
                                value={ApplicationState?.PurchasePrice}
                                onChange={(e) => setApplicationState({ ...ApplicationState, PurchasePrice: e.target.value.replace(/^0/, "") })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'PurchasePrice', error: seterrorPurchasePrice })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Purchase Price"
                                min="0"
                            />
                            {errorPurchasePrice !== "" && <span className="error_medotory">{errorPurchasePrice}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                            <Button className="registerbtn" id="registerbtn" onClick={() => SubmitRegister()}>
                                Next
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default BikePurchaseData;