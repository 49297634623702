/* eslint-disable */
import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import "./assets/css/style.css";
// import "./assets/css/pdfview.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { SELECT_SELLER_URL, INSPECTION_IMAGES_URL, TYPES_OF_BICYCLE_URL, BIKE_PURCHASE_DATA_URL, OFFER_URL, CUSTOMER_DETAILS_URL, DETAILS_UPLOAD_SCORE_URL, BIKE_CONDITIONS_URL, LOGIN_URL, IMAGE_PREVIEW_HTML_URL, IMAGE_PREVIEW_SECOND_HTML_URL, IMAGE_PREVIEW_THIRD_HTML_URL, IMAGE_PREVIEW_FOUR_HTML_URL, IMAGE_PREVIEW_FIVE_HTML_URL } from './components/Shared/constant';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWebSocket from './Api/UseWebsocket';
import { useDispatch, useSelector } from 'react-redux';
import Welcome from './components/Registerproduct';
import Typesofbicycle from './components/Registerproduct/Typesofbicycle';
import Registerproduct from './components/Registerproduct/Registerproduct';
import Congrats from './components/Registerproduct/Congrats';
import { SearchPincodeList, ErrorMessageFromRedux, clearRedux } from './redux/actions/adminActions';
import Login from "./components/Registerproduct/Login";
import BikeCondition from './components/Registerproduct/BikeCondition';
import InspectionImages from './components/Registerproduct/InspectionImages';
import DetailsUploadScore from './components/Registerproduct/DetailsUploadScore';
import ApplicableOffer from './components/Registerproduct/ApplicableOffer';
import wsSend_request from './Api/ws/ws_request';
import "./service-worker";
import BikePurchaseData from './components/Registerproduct/BikePurchaseData';
import PermissionDealerCustomer from './components/Common/PermissionDealerCustomer';
import ImagePreviewToHtml from './components/Registerproduct/htmlforpdf/ImagePreviewToHtml';
import ImagePreviewToHtml_Second from './components/Registerproduct/htmlforpdf/ImagePreviewToHtml_Second';
import ImagePreviewToHtml_Third from './components/Registerproduct/htmlforpdf/ImagePreviewToHtml_Third';
import ImagePreviewToHtml_Four from './components/Registerproduct/htmlforpdf/ImagePreviewToHtml_Four';
import ImagePreviewToHtml_Five from './components/Registerproduct/htmlforpdf/ImagePreviewToHtml_Five';
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const Dispatch = useDispatch();
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  const [ GetCurrentPath, setGetCurrentPath ] = useState("");
  const { cacheClear_, device_id, accessToken, sellerPermissionModal, dealerCustomerStatus } = useSelector((state) => state.adminReducers);

  // websocket connection
  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}/guest/`, setGetWEBSocket, Dispatch);

  useEffect(() => {
    return () => {
        // Dispatch(SearchPincodeList({}));
        Dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };
  }, [ GetCurrentPath ]);

  useEffect(() => {
    if(cacheClear_ === undefined) {
      Dispatch(clearRedux());
    };
  }, [ cacheClear_ ]);


  // get brand list all
  useEffect(() => {
    let param = {
        "transmit": "single",
        "url": "refurbished_brand_list",
        "request": {
            "search": "",
            "limit": 5000,
            "page_no": 1,
            "status": true
        },
        "DeviceId": device_id
    };
    wsSend_request(GetWEBSocket, param);
  }, [ GetWEBSocket, GetCurrentPath ]);

  // get refurbished point list all
  useEffect(() => {
    let param = {
        "transmit": "single",
        "url": "refurbished_point_list",
        "request": {
            "search": "",
            "limit": 5000,
            "page_no": 1,
            "status": true
        },
        "DeviceId": device_id
    };
    wsSend_request(GetWEBSocket, param);
  }, [ GetWEBSocket, GetCurrentPath ]);

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
    <div className="wrapper">
        {dealerCustomerStatus !== undefined && (<React.Fragment>
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<Navigate replace to={(!dealerCustomerStatus?.dealerExist && !dealerCustomerStatus?.customerExist) ? SELECT_SELLER_URL : SELECT_SELLER_URL}/>} />
                <Route path={SELECT_SELLER_URL} element={<PermissionDealerCustomer />} />
                {dealerCustomerStatus?.dealerExist && (<React.Fragment>
                    <Route path={LOGIN_URL} element={accessToken === "" ? <Login /> : <Navigate  replace to={CUSTOMER_DETAILS_URL}/>} />
                    <Route path={CUSTOMER_DETAILS_URL} element={accessToken !== "" ? <Welcome setGetCurrentPath={setGetCurrentPath} /> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={BIKE_PURCHASE_DATA_URL} element={accessToken !== "" ? <BikePurchaseData setGetCurrentPath={setGetCurrentPath} /> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={TYPES_OF_BICYCLE_URL} element={accessToken !== "" ? <Typesofbicycle setGetCurrentPath={setGetCurrentPath}/> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={BIKE_CONDITIONS_URL} element={accessToken !== "" ? <BikeCondition setGetCurrentPath={setGetCurrentPath}/> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={INSPECTION_IMAGES_URL} element={accessToken !== "" ? <InspectionImages setGetCurrentPath={setGetCurrentPath}/> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={DETAILS_UPLOAD_SCORE_URL} element={accessToken !== "" ? <DetailsUploadScore setGetCurrentPath={setGetCurrentPath}/> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path={OFFER_URL} element={accessToken !== "" ? <ApplicableOffer setGetCurrentPath={setGetCurrentPath}/> : <Navigate replace to={LOGIN_URL}/>} />
                    <Route path="*" element={accessToken !== "" ? <Navigate replace to={CUSTOMER_DETAILS_URL}/> : <Navigate replace to={LOGIN_URL}/>} />
                </React.Fragment>)}
                
                {dealerCustomerStatus?.customerExist && (<React.Fragment>
                    <Route path={CUSTOMER_DETAILS_URL} element={<Welcome setGetCurrentPath={setGetCurrentPath} />} />
                    <Route path={BIKE_PURCHASE_DATA_URL} element={<BikePurchaseData setGetCurrentPath={setGetCurrentPath} /> } />
                    <Route path={TYPES_OF_BICYCLE_URL} element={<Typesofbicycle setGetCurrentPath={setGetCurrentPath}/> } />
                    <Route path={BIKE_CONDITIONS_URL} element={<BikeCondition setGetCurrentPath={setGetCurrentPath}/> } />
                    <Route path={INSPECTION_IMAGES_URL} element={<InspectionImages setGetCurrentPath={setGetCurrentPath}/> } />
                    <Route path={DETAILS_UPLOAD_SCORE_URL} element={<DetailsUploadScore setGetCurrentPath={setGetCurrentPath}/> } />
                    <Route path={OFFER_URL} element={<ApplicableOffer setGetCurrentPath={setGetCurrentPath}/> } />
                    <Route path="*" element={<Navigate replace to={CUSTOMER_DETAILS_URL}/> } />
                </React.Fragment>)}

                <Route path={IMAGE_PREVIEW_HTML_URL} element={<ImagePreviewToHtml /> } />
                <Route path={IMAGE_PREVIEW_SECOND_HTML_URL} element={<ImagePreviewToHtml_Second /> } />
                <Route path={IMAGE_PREVIEW_THIRD_HTML_URL} element={<ImagePreviewToHtml_Third /> } />
                <Route path={IMAGE_PREVIEW_FOUR_HTML_URL} element={<ImagePreviewToHtml_Four /> } />
                <Route path={IMAGE_PREVIEW_FIVE_HTML_URL} element={<ImagePreviewToHtml_Five /> } />
              </Routes>
            </BrowserRouter>
        </React.Fragment>)}
        
        <ToastContainer />
    </div>
  </WebSocketContext.Provider>);
}

export default App;
