/* eslint-disable no-restricted-globals */
import { precacheAndRoute } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { StaleWhileRevalidate } from 'workbox-strategies';

// Precache manifest will be injected into self.__WB_MANIFEST
precacheAndRoute(self.__WB_MANIFEST || []);

// Example of caching strategy for API requests
registerRoute(
  ({ request }) => request.destination === 'document',
  new StaleWhileRevalidate({
    cacheName: 'html-cache',
  })
);
