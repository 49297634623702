/* eslint-disable */
import React from "react";
import { Button, Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_DETAILS_URL } from "../Shared/constant";
// sample slim build usage
import { GetCustomerDetails, GetBikePurchaseDate, StartCommonLoader, ErrorMessageFromRedux, SearchPincodeList, GetTypesOfBicycles, GetBikeConditionsFilters, getInspectionVideos, getBikeCondtionsSteps, getRefurbishedResponse, ClearFormSetFutios } from "../../redux/actions/adminActions";
import FranchiseHeader from "../Common/FranchiseHeader";

const ApplicableOffer = (props) => {
    const { setGetCurrentPath } = props;
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { refurbishedResponse } = useSelector((state) => state.adminReducers);

    // sign in function
    const SubmitRegister = () => { 
        dispatch(GetCustomerDetails({}));
        dispatch(GetBikePurchaseDate({}));
        dispatch(GetTypesOfBicycles({}));
        dispatch(GetBikeConditionsFilters([]));
        dispatch(getRefurbishedResponse({}));
        dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
        dispatch(getBikeCondtionsSteps(0));
        dispatch(getInspectionVideos([]));
        dispatch(StartCommonLoader(false));
        dispatch(SearchPincodeList({}))
        Navigate(CUSTOMER_DETAILS_URL);
    };

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct">
                    <div className="formloginset">
                        <div className="rpoduct_offer_wrappers">
                            <h3>Here are the Best Offers On <br/> Emotorad Bikes</h3>
                            <h4>Applicable Offer</h4>
                            <div className="tabkle_wrapper"  id="tabkle_wrapper">
                                <Table hover >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50%" }}>E-Bike</th> 
                                            <th style={{ width: "50%" }}>Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {refurbishedResponse?.brand_list?.map((elm, index) => {
                                            return(<tr key={index}>
                                                <td>{elm?.key}</td>
                                                <td>{elm?.value}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                            <Button className="registerbtn" id="registerbtn" onClick={() => SubmitRegister()}>Complete </Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default ApplicableOffer;