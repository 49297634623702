import React, { useState } from "react";
import { useSelector } from "react-redux";
import CommonewModel from "../Common/CommonewModel";
import { useLocation } from "react-router-dom";

const GuestLayout = ({children}) => {
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const Location = useLocation()?.pathname?.split("/")[1]
    const { accessToken, dealerCustomerStatus } = useSelector((state) => state.adminReducers);

    // logout function
    const LogoutFnct = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    };

    return(<React.Fragment>
        {(!dealerCustomerStatus?.customerExist && Location !== "select-seller") && (<>
            {(window?.screen?.width > 600 && accessToken) && (<div className="header_logout" onClick={() => LogoutFnct()}>
                <i className="bi bi-power"></i>
            </div>)}
        </>)}

        <section className="mainloginwrps">{children}</section>

        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
    </React.Fragment>)
}

export default GuestLayout;