/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { GetBikeConditionsFilters, getBikeCondtionsSteps } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate } from "react-router-dom";
import { INSPECTION_IMAGES_URL, FILE_AUTH_URL } from "../Shared/constant";
import CommonLoader from "../Common/CommonLoader";
import WebcamCapture from "../Common/WebcamCapture";
import ImagePreviwer from "../Common/ImagePreviwer";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import FranchiseHeader from "../Common/FranchiseHeader";
import RangeSlider from "../Common/RangeSlider";
import CameraIconActive from "../../assets/img/camera_icon_Active.svg";
import CameraIconInactive from "../../assets/img/camera_icon_inActive.svg";

const BikeCondition = (props) => {
    const { setGetCurrentPath } = props;
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { cacheClear_, ClearFormSet, bikeconditionFilter, typesofbicycle, getrefurbished_point_list, bikeCondtionsSteps } = useSelector((state) => state.adminReducers);
    
    const [ BikeCondtions, setBikeCondtions ] = useState(() => getrefurbished_point_list?.map((elm) => {
        return{
            id: elm?.id,
            key: elm?.refurbished_point,
            point_score: elm?.point_score,
            is_image: elm?.is_image,
            point_images: elm?.point_images,
            Images: JSON.parse(elm?.point_images)?.map((elx, index) => {
                return{
                    id: "images_id_"+index,
                    name:  elx,
                    data: {},
                    base64_encode: ""
                };
            }),
            ReferenceImages: JSON.parse(elm?.reference_images)?.map((elx, index) => {
                return{
                    id: index,
                    range:  elx?.range,
                    image_url: elx?.images
                };
            }),
            value: 0,
        }
    }));
    const [ FormValid, setFormValid ] = useState(false);

    const [ OpenCameraModal, setOpenCameraModal ] = useState({
        open: false,
        url: "",
        parent_id: "",
        current_id: "",
    });
    const [ ImagePreviewerModal, setImagePreviewerModal ] = useState({
        open: false,
        Range: "",
        Images: ""
    });
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);

    useEffect(() => {
        if(bikeconditionFilter !== undefined) {
            if(bikeconditionFilter?.length > 0) {
                setBikeCondtions(bikeconditionFilter);
            };
        }
    }, [  ]);

    // upload file
    const UploadImage = (url, id, ids) => {
        setOpenCameraModal({
            ...OpenCameraModal,
            open: !OpenCameraModal?.open,
            url: url,
            parent_id: id,
            current_id: ids,
        });
    };

    // close sidebar
    const CloseSidebar = () => {
        setOpenCameraModal({
            ...OpenCameraModal,
            open: false,
            url: "",
            parent_id: "",
            current_id: "",
        })
    };

    // remove image
    const RemoveImage = (parent_id, current_id) => {
        const UpdateImageData = BikeCondtions?.map((elm) => {
            if(elm?.id === parent_id) {
              return{
                ...elm,
                Images: elm?.Images?.map((elz) => {
                  if(elz?.id === current_id) {
                    return {
                      ...elz,
                      data: {},
                      base64_encode: ""
                    };
                  } else {
                    return elz;
                  }
                })
              }
            } else {
              return elm;
            }
        });
        setBikeCondtions(UpdateImageData);
        dispatch(GetBikeConditionsFilters(UpdateImageData));
    };

    // sign in function
    const SubmitBikeCondtions = () => { 
        setFormValid(true);
        let CurrentSteps;
        if(typesofbicycle?.DoHaveSuspension?.value === "No") {
            CurrentSteps = BikeCondtions?.filter((elm) => elm?.key !== "Suspension")[bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps];
        } else {
            CurrentSteps = BikeCondtions[bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps];
        };
        
        const ImagesValid = CurrentSteps?.Images?.filter((elm) => Object?.keys(elm?.data)?.length !== 0);
        
        if(CurrentSteps, CurrentBikeCondtions?.value !== 0 && ImagesValid?.length === CurrentSteps?.Images?.length) {
            setFormValid(false);
            OnSubmitScrollTop();
            NextQustSteps();
        };
    };

    // next questions
    const NextQustSteps = () => {
        if(typesofbicycle?.DoHaveSuspension?.value === "No") {
            if(BikeCondtions?.filter((elm) => elm?.key !== "Suspension")?.length !== ((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) + 1)) {
                dispatch(getBikeCondtionsSteps((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) + 1));
                dispatch(GetBikeConditionsFilters(BikeCondtions));
            } else {
                Navigate(INSPECTION_IMAGES_URL);
            };
        } else {
            if(BikeCondtions?.length !== ((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) + 1)) {
                dispatch(getBikeCondtionsSteps((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) + 1));
                dispatch(GetBikeConditionsFilters(BikeCondtions));
            } else {
                Navigate(INSPECTION_IMAGES_URL);
            };
        };
    };

    // back steps function
    const BackStepFunction = () => {
        if((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) !== 0) {
            dispatch(getBikeCondtionsSteps((bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) - 1))
        };
    };

    // scroll top
    const OnSubmitScrollTop = () => {
        const GetId = document.getElementById('BikeConditionID');
        GetId?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "purchase_add") {
            Navigate(INSPECTION_IMAGES_URL)
        };
    }, [ ClearFormSet ]);

    // preview image modal
    const ImagePreviewFunction = (image_url, range) => {
        setImagePreviewerModal({
            open: !ImagePreviewerModal?.open,
            Range: range,
            Images: image_url
        });
    };

    // close image preview modal
    const CloseImageModal = () => {
        setImagePreviewerModal({
            open: false,
            Range: "",
            Images: ""
        });
    };

    // get current bike condtions
    const CurrentBikeCondtions = typesofbicycle?.DoHaveSuspension?.value === "No" ? BikeCondtions?.filter((elm) => elm?.key !== "Suspension")[(bikeCondtionsSteps === undefined || bikeCondtionsSteps === null) ? 0 : bikeCondtionsSteps] : BikeCondtions[(bikeCondtionsSteps === undefined || bikeCondtionsSteps === null) ? 0 : bikeCondtionsSteps];

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper" >
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct" >
                    <h4>Bike Condition</h4>
                    <div className="formloginset">
                        <div className="radio_buttons_selections_wrap">
                            <div className="radio_buttons_selections" >
                                <h5>{(bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) + 1}. {CurrentBikeCondtions?.key}</h5>
                                <RangeSlider 
                                    CurrentBikeCondtions={CurrentBikeCondtions}
                                    BikeCondtions={BikeCondtions} 
                                    setBikeCondtions={setBikeCondtions} 
                                />
                                {(FormValid && CurrentBikeCondtions?.value === 0) && (<span className="error_medotory error_medotory_range">Please select <div style={{ textTransform: "lowercase" }}>{CurrentBikeCondtions?.key}</div> condition</span>)}
                            </div>
                            <div className="imageRefrance_wrapper">
                                <h4>Reference Image:</h4>
                                <ul className="iamge_boxed">
                                    {CurrentBikeCondtions?.ReferenceImages?.map((elm, index) => {
                                        return(<li key={index}>
                                            <Image src={FILE_AUTH_URL + elm?.image_url?.view_file_url} onError={(e) => e.target.src = DefaultImage} onClick={() => ImagePreviewFunction(FILE_AUTH_URL + elm?.image_url?.view_file_url, elm?.range)} alt="image" />
                                            <span className="rangeforimage">Range: {elm?.range}</span>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                            {CurrentBikeCondtions?.is_image && (<div className="imageRefrance_wrapper" >
                                <h4>Inspection Images:</h4>
                                <div className="formloginset" >
                                    {CurrentBikeCondtions?.Images?.map((elm, index) => {
                                        return(<Form.Group className="mb-3 upload_file_box_wraps" key={index}>
                                            <div className="upload_file_box">
                                                {Object.keys(elm?.data)?.length === 0 ? (<label className="uploadfiles" onClick={() => UploadImage(elm?.name, CurrentBikeCondtions?.id, elm?.id)}>
                                                    <Image src={CameraIconInactive} alt="camera" />
                                                    Upload Image
                                                </label>) : (<label className="uploadfiles uploadfilesuploaded" aria-disabled={true}>
                                                    <Image src={CameraIconActive} alt="camera" />
                                                    <div className="uplaoded_file_name">{elm?.data?.name}</div>
                                                    <i className="bi bi-x-circle-fill" onClick={() => RemoveImage(CurrentBikeCondtions?.id, elm?.id)}></i>
                                                </label>)}
                                                <span>{elm?.name}</span>
                                            </div>
                                        </Form.Group>)
                                    })}
                                    <div className="error_message_handle">
                                        {CurrentBikeCondtions?.Images?.map((elm, index) => {
                                            if(FormValid && Object.keys(elm?.data)?.length === 0) {
                                                return(<div className="alert-danger-wrapper" key={index} >
                                                    Please upload <div style={{ textTransform: "lowercase", display: "inline" }}>{elm?.name}</div>.
                                                </div>)
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>)}
                            <Form.Group className="mb-3 btn-groups-wrapper" style={{ textAlign: "center" }}>
                                {(bikeCondtionsSteps === undefined ? 0 : bikeCondtionsSteps) !== 0 && (<Button className="backbtnbtn" id="registerbtn" onClick={() => BackStepFunction()}>
                                    Back
                                </Button>)}
                                <Button className="registerbtn" id="registerbtn" onClick={() => SubmitBikeCondtions()}>
                                    Next
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* camera open modal */}
         {OpenCameraModal?.open && (
            <WebcamCapture 
                OpenCameraModal={OpenCameraModal} 
                CloseSidebar={CloseSidebar} 
                setUploadFileLoader={setUploadFileLoader}
                BikeCondtions={BikeCondtions} 
                setBikeCondtions={setBikeCondtions}
            />
        )}
        {OpenCameraModal?.open && (<div className="background_wrapper" onClick={() => CloseSidebar()}></div>)}
        {UploadFileLoader && (<CommonLoader />)}

        {ImagePreviewerModal?.open && (<div className="background_wrapper" onClick={() => CloseImageModal()}></div>)}
        {ImagePreviewerModal?.open && (<ImagePreviwer ImagePreviewerModal={ImagePreviewerModal} CloseImageModal={CloseImageModal} />)}
    </GuestLayout>)
}

export default BikeCondition;