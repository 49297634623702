import React from "react";
import { Image } from "react-bootstrap";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";

const ImagePreviwer = (props) => {
    const { ImagePreviewerModal, CloseImageModal } = props;

    return(<div className="Image_preview_wrapper">
        <i className="bi bi-x-lg" onClick={CloseImageModal}></i>
        <h4>Range : {ImagePreviewerModal?.Range}</h4>
        <div className="preivew_image">
            <Image src={ImagePreviewerModal?.Images} onError={(e) => e.target.src = DefaultImage} alt="image" />
        </div>
    </div>)
};

export default ImagePreviwer;