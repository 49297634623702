import React, { useState } from "react";
import LogoImage from "../../assets/img/new/Logo_white.svg";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import CommonewModel from "../Common/CommonewModel";

const FranchiseHeader = (props) => {
    // const { logotype } = props;
    const { accessToken } = useSelector((state) => state.adminReducers);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // logout function
    const LogoutFnct = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    };

    return(<div className={accessToken ? "franhcise_header after_login_franhcise_header" : "franhcise_header"}>
        <h1 className="logoheader">
            {/* <Image src={logotype === "white" ? LogoWhiteImage : LogoImage} alt="logo" /> */}
            <Image src={LogoImage} alt="logo" />
        </h1>
        {(window?.screen?.width < 600 && accessToken) && (<div className="header_logout" onClick={() => LogoutFnct()}>
            <i className="bi bi-power"></i>
        </div>)}

        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
    </div>)
};

export default FranchiseHeader;