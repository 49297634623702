import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getDealerCustomerStatus } from "../../redux/actions/adminActions";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_DETAILS_URL, LOGIN_URL } from "../Shared/constant";
import GuestLayout from "../Shared/GuestLayout";
import FranchiseHeader from "./FranchiseHeader";

const PermissionDealerCustomer = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();

    // customer dealer status
    const CommonStatusUpdate = (url) => {
        if(url === "dealer") {
            Dispatch(getDealerCustomerStatus({
                dealerExist: true,
                customerExist: false,
            }));
            Navigate(LOGIN_URL);
        } else {
            Dispatch(getDealerCustomerStatus({
                dealerExist: false,
                customerExist: true,
            }));
            Navigate(CUSTOMER_DETAILS_URL);
        };
    };

    return(<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper" id="permision_Modal_wrapper_main">
            <div className="main_form_Box permision_Modal_wrapper">
                <FranchiseHeader />
                <h1 className="headin">Seller Type</h1>
                <div className="btn_groups_modal">
                    <Button onClick={() => CommonStatusUpdate("dealer")}>Dealer</Button>
                    <Button onClick={() => CommonStatusUpdate("customer")} id="customerseller">Customer</Button>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default PermissionDealerCustomer;