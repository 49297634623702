/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { useDispatch, useSelector } from "react-redux";
import { StartCommonLoader, GetBikePurchaseDate, StartMainCommonLoader, GetTypesOfBicycles, getInspectionVideos, getBikeCondtionsSteps, ClearFormSetFutios, GetBikeConditionsFilters, getRefurbishedResponse, GetCustomerDetails, getModalVerifyOpenClose, SearchPincodeList, ErrorMessageFromRedux, getDealerCustomerStatus } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import { useNavigate, useLocation } from "react-router-dom";
import { BIKE_PURCHASE_DATA_URL, SELECT_SELLER_URL } from "../Shared/constant";
import FranchiseHeader from "../Common/FranchiseHeader";
import VerifyModal from "../Common/VerifyModal";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const Welcome = (props) => {
    const { setGetCurrentPath } = props;
    const pathname = useLocation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { ErrorMessageFromReddux, cacheClear_, searchPincodeList, commonLoader, customerdetails, verifyFiedModal, dealerCustomerStatus } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        FullName: "",
        Email: "",
        Mobile: "",
        PINCode: "",
    });

    // error state
    const [errorFullName, seterrorFullName] = useState("");
    const [errorMobile, seterrorMobile] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorPINCode, seterrorPINCode] = useState("");
    
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    useEffect(() => {
        if(customerdetails !== undefined) {
            if(Object?.keys(customerdetails)?.length > 0) {
                setApplicationState({
                    ...ApplicationState,
                    FullName: customerdetails?.FullName,
                    Email: customerdetails?.Email,
                    Mobile: customerdetails?.Mobile,
                    PINCode: customerdetails?.PINCode,
                });
            };
        };
    }, []);

    useEffect(() => {
        dispatch(StartCommonLoader(false));
        dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        ErrorMessageFromReddux.API=""
        setGetCurrentPath(pathname);
    }, [pathname]);

    // pin code error get from redux
    useEffect(() => {
        if (ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        } 
    }, [ErrorMessageFromReddux ]);

    // on Enter search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = () => {
        if (ApplicationState?.PINCode?.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "single",
                "url": "pin_code_search",
                "request": {
                    "pin_code": ApplicationState?.PINCode,
                },
            };
            wsSend_request(websocket, param);
        } else {
            dispatch(StartCommonLoader(false));
            dispatch(SearchPincodeList({}));
        }
    };

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({ ...ApplicationState, PINCode: e.target.value });
        if(e.target.value?.length === 6  ) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "single",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
            };
            wsSend_request(websocket, param);
            CheckValid(e.target.value.trim(), { type: 'PINCode', error: seterrorPINCode });
        }
        if (e.target.value === "") {
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    };

    // sign in function
    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    const SubmitRegister = () => {
        if(dealerCustomerStatus?.customerExist) {
            if(ApplicationState?.Brand?.value === "Unbranded") {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "") {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true && ApplicationState?.PINCode.trim()?.length === 6) {
                        dispatch(GetCustomerDetails(ApplicationState));
                        Navigate(BIKE_PURCHASE_DATA_URL);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
                    };
                } else {
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'Flame', error: seterrorFullName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
                    CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
                };
            } else {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "") {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true && ApplicationState?.PINCode.trim()?.length === 6) {
                        dispatch(GetCustomerDetails(ApplicationState));
                        Navigate(BIKE_PURCHASE_DATA_URL);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
                    };
                } else {
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'Flame', error: seterrorFullName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
                    CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
                };
            };
        } else {
            if(ApplicationState?.Brand?.value === "Unbranded") {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "") {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                        dispatch(GetCustomerDetails(ApplicationState));
                        Navigate(BIKE_PURCHASE_DATA_URL);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    };
                } else {
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'Flame', error: seterrorFullName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
                };
            } else {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "") {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                        dispatch(GetCustomerDetails(ApplicationState));
                        Navigate(BIKE_PURCHASE_DATA_URL);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    };
                } else {
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'Flame', error: seterrorFullName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
                };
            };
        };
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // back to Permission Status
    const BackPermisionResellbtn = () => {
        Navigate(SELECT_SELLER_URL);
        dispatch(getDealerCustomerStatus({
            dealerExist: false,
            customerExist: false,
        }));

        dispatch(GetCustomerDetails({}));
        dispatch(GetBikePurchaseDate({}));
        dispatch(GetTypesOfBicycles({}));
        dispatch(GetBikeConditionsFilters([]));
        dispatch(getRefurbishedResponse({}));
        dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
        dispatch(getBikeCondtionsSteps(0));
        dispatch(getInspectionVideos([]));
        dispatch(StartCommonLoader(false));
        dispatch(SearchPincodeList({}))
    };

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct">
                    <h4>Customer Details </h4>
                    <div className="formloginset">
                        <Form.Group className="mb-3" >
                            <Form.Label>Full Name*</Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.FullName}
                                onChange={(e) => setApplicationState({ ...ApplicationState, FullName: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'Flame', error: seterrorFullName })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Full Name"
                            />
                            {errorFullName !== "" && <span className="error_medotory">{errorFullName}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Phone Number*</Form.Label>
                            <Form.Control
                                type="number"
                                className="inputBoxs"
                                value={ApplicationState?.Mobile}
                                onChange={(e) => setApplicationState({ ...ApplicationState, Mobile: e.target.value.replace(/^0/, "") })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'phone', error: seterrorMobile })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Phone Number"
                                min="0"
                            />
                            {errorMobile !== "" && <span className="error_medotory">{errorMobile}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email ID <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.Email}
                                onChange={(e) => setApplicationState({ ...ApplicationState, Email: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: seterrorEmail })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Email ID"
                            />
                            {errorEmail !== "" && <span className="error_medotory">{errorEmail}</span>}
                        </Form.Group>
                        {dealerCustomerStatus?.customerExist && (<React.Fragment>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    PIN Code
                                    <span className="mendatory_star">*</span>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                            Search by either hitting the enter button or clicking on the search icon.
                                        </Tooltip>}
                                    >
                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="serachBoxWIth">
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.PINCode}
                                        onChange={(e) => PinCOdeSearchFunction(e)}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'PINCode', error: seterrorPINCode })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                            onEnterPincodeSearch(e)
                                        }}
                                        disabled={commonLoader}
                                        maxLength={6}
                                        placeholder="Enter PIN Code"
                                    />
                                    <Button className="searchbtn" onClick={() => SearchPincode()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                            <path d="M21 21l-6 -6"></path>
                                        </svg>
                                    </Button>
                                    {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                        <div className="pincode_error">
                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({ ...ApplicationState, PINCode: "" })} style={{ color: "red" }}></i>
                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#34b739" }}></i>)}
                                        </div>
                                    </>)}
                                    {commonLoader && (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                                </div>
                                {errorPINCode !== "" && <span className="error_medotory">{errorPINCode}</span>}
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>City</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" || ErrorMessageFromReddux?.API  ? searchPincodeList?.city?.district_name : ""}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>State</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" || ErrorMessageFromReddux?.API ? searchPincodeList?.state?.state_name : ""}
                                </div>
                            </Form.Group>
                        </React.Fragment>)}
                        <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                            <Button className="registerbtn" id="registerbtn" onClick={() => SubmitRegister()}>
                                Next
                            </Button>
                            {dealerCustomerStatus?.customerExist && (
                            <button className="back_btn" onClick={() => BackPermisionResellbtn()}>
                                <i className="bi bi-arrow-left-short"></i> Back
                            </button>)}
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>

        {dealerCustomerStatus?.dealerExist && (<React.Fragment>
            {(verifyFiedModal === undefined || verifyFiedModal) && (<VerifyModal />)}
            {(verifyFiedModal === undefined || verifyFiedModal) && (<div className="verify_background" onClick={() => dispatch(getModalVerifyOpenClose(false))}></div>)}
        </React.Fragment>)}
    </GuestLayout>)
}

export default Welcome;