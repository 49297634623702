import React from "react";

const ImagePreviewToHtml_Five = () => {
    return(<div className="image_preview_wrapper" id="contentToCapture_Five" >
        <div className="main_container">
            <div className="user_details_with_cycle" style={{ paddingTop: "0px" }}>
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <div className="evalutions_colus_wrapper health_evalutions_colus_wrapper">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>4. Decently Used</h5>
                                    <p>Your cycle/e-cycle shows heavy use with all components exhibiting significant wear. Tyres may need replacement soon, and the frame and rims have noticeable damage. Brakes and gears may require servicing. The paintwork has several scratches and dents. E-cycle components are ageing, with the motor, battery, and display possibly requiring repair.Your cycle/e-cycle has a low resale value.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>3. Adequately Loved</h5>
                                    <p>Your cycle/e-cycle is in poor condition with most components showing extensive wear. Tyres are worn out, the frame and rims are damaged, and brakes and gears need significant work. The paintwork is heavily scratched and dented. E-cycle components are close to the end of their lifespan with the motor, battery, and display needing repair or replacement.Your cycle/e-cycle has a very low resale value.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>2. Weathered Highly</h5>
                                    <p>Your cycle/e-cycle is in very poor condition with most components barely functional. Tyres need immediate replacement, the frame and rims are heavily damaged, and brakes and gears are non-functional. The paintwork is severely damaged. E-cycle components are non-functional or require immediate replacement. Your cycle/e-cycle has minimal resale value, close to scrap.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>1. Exchange Now</h5>
                                    <p>Your cycle/e-cycle is in scrap condition. All components are non-functional or broken. Tyres are unusable, the frame is severely damaged, and brakes and gears do not work. The paintwork is beyond repair. E-cycle components like the motor, battery, and display are completely non-functional. Your cycle/e-cycle has a negligible resale value, equivalent to scrap.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ImagePreviewToHtml_Five;