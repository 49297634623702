import React from "react";
import { Image } from "react-bootstrap";
import LogoImage from "../../../assets/img/pdftohtml/e motorad.svg";
import UserCycleImage from "../../../assets/img/pdftohtml/user_cycle.svg";
import CycleWorksImage from "../../../assets/img/pdftohtml/banner.png";
import { useSelector } from "react-redux";

const ImagePreviewToHtml = () => {
    const { refurbishedResponse } = useSelector((state) => state.adminReducers);

    return(<div className="image_preview_wrapper" id="contentToCapture" >
        <header className="header_section">
            <Image src={LogoImage} alt="logo" />
        </header>
        <div className="banner_section">
            <h2>Hey {refurbishedResponse?.full_name},<br />
                This is your free bike health checkup
            </h2>
            <p>Here's everything we can tell you about your cycle: from the component condition to resale value and tips to keep your ride fit</p>
        </div>
        <div className="lets_scroll_devide">Let's roll in, right away!</div>
        <div className="main_container">
            <div className="cycle_check_section">
                <Image src={CycleWorksImage} alt="cycle_works" />
                <p>This Cycle Health Check is a comprehensive evaluation of a bicycle's condition to determine its current state and calculate its resale value. This check involves a detailed inspection of various components, which are rated by a team of experts and supported by photographic evidence. The overall health of the cycle is categorised into four main levels: Great (Next to New), Good, Needs Improvement, and Scrap Worthy.</p>
            </div>
            <div className="user_details_with_cycle">
                <div className="cycle_wraps_main_head" style={{ boxShadow: "6px 6px 12px -5px #ccc" }}>
                    <h4>{refurbishedResponse?.full_name}</h4>
                    <div className="cycel_user_deta">
                        <div>{refurbishedResponse?.mobile}</div>
                        <div>{refurbishedResponse?.email}</div>
                    </div>
                </div>
                <div className="cycle_wraps_main" style={{ boxShadow: "6px 6px 12px -5px #ccc" }}>
                    <div className="row">
                        <div className="col-md-6" style={{ paddingRight: "0px", position: "absolute", left: "13px", width: "57%" }}>
                            <div className="bike_details_section">
                                <h4>Bike Details</h4>
                                <div className="bike_details_wrapper">
                                    <div className="bike_row">
                                        <div className="col-md-4">
                                            <div className="bike_detils_blog">
                                                <span>Frame Number</span>
                                                <div>{refurbishedResponse?.frame_number}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="bike_detils_blog">
                                                <span>Purchase Date</span>
                                                <div>{refurbishedResponse?.purchase_month}/{refurbishedResponse?.purchase_year}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="bike_detils_blog">
                                                <span>Gears</span>
                                                <div>{refurbishedResponse?.is_geared ? refurbishedResponse?.no_geared : 0} Speed</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bike_row">
                                        <div className="col-md-4">
                                            <div className="bike_detils_blog">
                                                <span>Purchase Price</span>
                                                <div>₹{refurbishedResponse?.purchase_price}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="bike_detils_blog">
                                                <span>Brand</span>
                                                <div>{refurbishedResponse?.brand}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 cycle_detail_image" >
                            <Image src={UserCycleImage} alt="cycle_works" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ImagePreviewToHtml;