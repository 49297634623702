import React from "react";

const ImagePreviewToHtml_Four = () => {
    return(<div className="image_preview_wrapper" id="contentToCapture_Four" >
        <div className="main_container">
            <div className="user_details_with_cycle" style={{ paddingTop: "0px" }}>
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <h4>Evaluation Process</h4>
                    <div className="evalutions_colus_wrapper">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>Component Inspection:</h5>
                                    <p>The bicycle owner is required to submit pictures of key components including the frame, tyres, brakes, chain, gears, saddle, handlebars, and pedals after which each will be inspected by a team of experts.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>Condition Rating:</h5>
                                    <p>Each component is rated on a scale of 1 to 10, with 1-3 being a variation of excellent condition (like new) and 4-7 ranging in good condition with some signs of weathering and 8-10 indicating heavily worn or damaged.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>Overall Assessment:</h5>
                                    <p>The ratings and visual inspection are used to generate an overall health score for the bicycle, categorising it into one of four levels of condition.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>Resale Value Calculation:</h5>
                                    <p>Based on the overall health score, a resale value is determined, reflecting the current market conditions for bicycles in similar states.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="not_cols_evalutions">
                        <span>Did You know: </span>
                        A traditional cycle depreciates 60% in the first 18 months of its usage. Meanwhile an electric cycle only has a depreciation quotient of 20% in the same period.
                    </div>
                </div>
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <h4>Health Categories</h4>
                    <div className="evalutions_colus_wrapper health_evalutions_colus_wrapper">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>10. New</h5>
                                    <p>Your cycle/e-cycle is in mint condition, similar to new. All components including tyres, frame, wheels, rims, paintwork, brakes, gears, saddle, and handlebar show no signs of wear. For e-cycles, the motor, battery, and display are functioning perfectly, as if just out of the box. Your cycle/e-cycle holds the highest resale value with only the automatic yearly depreciation considered.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>9. Almost New</h5>
                                    <p>Your cycle/e-cycle is nearly in mint condition with minimal signs of use. All components are in excellent working order with very slight cosmetic blemishes, if any. The motor, battery, and display for e-cycles are in optimal condition. Your cycle/e-cycle holds a high resale value, just below brand new.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>8. Next to New</h5>
                                    <p>Your cycle/e-cycle shows minor signs of use. Tyres, frame, wheels, rims, and other components are in great condition with minor cosmetic wear. Brakes and gears function smoothly. E-cycle motor, battery, and display work efficiently. Your cycle/e-cycle has a high resale value with minor depreciation.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>7. Maintained Greatly</h5>
                                    <p>Your cycle/e-cycle has visible signs of use but all components are still in very good condition. Tyres show some wear, and there may be minor scratches on the frame and paintwork. Brakes and gears are greatly functional. For e-cycles, the motor, battery, and display are in good condition with no performance issues. Your cycle/e-cycle has a good resale value with moderate depreciation.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>6. Ageing Finely</h5>
                                    <p>Your cycle/e-cycle shows moderate signs of use. Tyres, frame, wheels, and rims have visible wear but are still functional. Brakes and gears are operational with some wear. The paintwork may have scratches. E-cycle components like the motor, battery, and display are still functional but may show some signs of ageing. Your cycle/e-cycle has a fair resale value.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="evalutions_colus">
                                    <h5>5. First Signs of Wear</h5>
                                    <p>Your cycle/e-cycle has noticeable wear and tear. Tyres, frame, wheels, and rims show significant use. Brakes and gears may need minor adjustments or maintenance. The paintwork has scratches and possibly small dents. E-cycle motor, battery, and display function but may need attention soon.Your cycle/e-cycle holds a moderate resale value.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ImagePreviewToHtml_Four;