/* eslint-disable */
import React from "react";
import { Image } from "react-bootstrap";
import MainCycleImage from "../../../assets/img/pdftohtml/main_cycle.svg";
import { useSelector } from "react-redux";

const ImagePreviewToHtml_Second = () => {
    const { bikeconditionFilter, typesofbicycle } = useSelector((state) => state.adminReducers);

    // If the number of points is greater than 5, then only show 5 items in the bike conditions.
    // const firstFiveItems = refurbishedResponse?.points?.length > 6 ? refurbishedResponse.points?.filter(elz => elz?.Images !== undefined).slice(0, 6) : refurbishedResponse.points?.filter(elz => elz?.Images !== undefined);

    const firstFiveItems = bikeconditionFilter?.length > 6 ? bikeconditionFilter?.filter(elz => elz?.Images !== undefined).slice(0, 6) : bikeconditionFilter?.filter(elz => elz?.Images !== undefined);

    // get current bike condtions details
    const GetCurrentConditions = (url) => {
        const currentItems = bikeconditionFilter?.filter((elm) => {
            if(elm?.key === url) {
                return elm;
            };
        });
        return currentItems[0]
    };

    return(<div className="image_preview_wrapper" id="contentToCapture_Second" >
        <div className="main_container">
            <div className="user_details_with_cycle" style={{ paddingTop: "0px" }}>
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <h4>Detailed Cycle Inspection Report</h4>
                    <div className="cycle_with_score">
                        <span className={GetCurrentConditions("Frame & Color")?.value === "10" ? "frame_score" : "frame_score active"}>
                            {GetCurrentConditions("Frame & Color")?.value}0
                            <small>%</small>
                        </span>
                        <span className={GetCurrentConditions("Tire Condition")?.value === "10" ? "rear_tire_score" : "rear_tire_score active"}>
                            {GetCurrentConditions("Tire Condition")?.value}0
                            <small>%</small>
                        </span>
                        <span className={GetCurrentConditions("Brakes front & rear")?.value === "10" ? "breaks_score" : "breaks_score active"}>
                            {GetCurrentConditions("Brakes front & rear")?.value}0
                            <small>%</small>
                        </span>
                        <span className={GetCurrentConditions("Gears")?.value === "10" ? "gears_score" : "gears_score active"}>
                            {GetCurrentConditions("Gears")?.value}0
                            <small>%</small>
                        </span>
                        <Image src={MainCycleImage} alt="main_cycle" />
                        <span className={GetCurrentConditions("Suspension")?.value === "10" ? "suspension_score" : "suspension_score active"}>
                            {GetCurrentConditions("Suspension")?.value}0
                            <small>%</small>
                        </span>
                        <span className={GetCurrentConditions("Tire Condition")?.value === "10" ? "front_tire_score" : "front_tire_score active"}>
                            {GetCurrentConditions("Tire Condition")?.value}0
                            <small>%</small>
                        </span>
                    </div>
                    <div className="bike_condtions_gallery"> 
                        <div className="row">
                            {firstFiveItems?.filter((elz) => {
                                if(typesofbicycle?.DoHaveSuspension?.label === "No") {
                                    if(elz?.key !== "Suspension") {
                                        return elz;
                                    };
                                } else {
                                    return elz;
                                };
                            })?.map((elm, index) => {
                                return(<div className="col-md-6 col-sm-6" key={index}>
                                    <div className="bike_gallery" >
                                        <h5>
                                            {elm?.key}
                                            <div className="bike_gallery_range">
                                                <ul className="custom_progress_relative_range">
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li>8</li>
                                                    <li>9</li>
                                                    <li>10</li>
                                                </ul>
                                                <div className="custom_progress_relative">
                                                    <div 
                                                        className={parseInt(elm?.value) === 0 ? "custom_progress_relative_fill first_range" : parseInt(elm?.value) === 10 ? "custom_progress_relative_fill ten_range" : "custom_progress_relative_fill"} 
                                                        style={{
                                                            width:
                                                              parseInt(elm?.value) === 1
                                                                ? `${parseInt(elm?.value * 10)}%`
                                                                : parseInt(elm?.value) > 1 && parseInt(elm?.value) < 10
                                                                ? `${parseInt(elm?.value * 10) - 2}%`
                                                                : `${parseInt(elm?.value * 10)}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </h5>
                                        <div className="images_gallery_wrapper">
                                            <div className="row">
                                                {elm?.Images?.map((elm, index) => {
                                                    return(<div className="col-md-6 col-sm-6" key={index}>
                                                        <Image src={elm?.base64_encode} alt={elm?.data?.name} />
                                                    </div>)
                                                })}
                                                {elm?.Images?.length === 1 && (<div className="col-md-6 col-sm-6">
                                                    <div className="images_gallery_dummy">
                                                        <i className="bi bi-card-image"></i>
                                                        <span>You have only one image on the left.</span>
                                                    </div>
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ImagePreviewToHtml_Second;