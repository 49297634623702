/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { StartCommonLoader, StartMainCommonLoader, GetTypesOfBicycles } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { BIKE_CONDITIONS_URL } from "../Shared/constant";
import UploadFileAPI from "../../Api/UploadFileAPI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import FranchiseHeader from "../Common/FranchiseHeader";

const Typesofbicycle = (props) => {
    const { setGetCurrentPath } = props;
    const pathname = useLocation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ImageDisplay, setImageDisplay] = useState("");
    const { ErrorMessageFromReddux, ClearFormSet, typesofbicycle } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        GearedType: "",
        NoOfGeared: "",
        FrameNumber: "",
        DoHaveInvoice: "",
        DoHaveSuspension: "",
        invoiceImage: null,
        invoiceImageName: "",
        YearsMonths: {
            Year: "",
            Month: "",
        },
    });

    const optionsTypesGear = [
        { key: 1, value: "Geared", label: "Geared"},
        { key: 2, value: "Non-Geared", label: "Non-Geared"},
    ];

    const optionsInvoiceYesNo = [
        { key: 1, value: "Yes", label: "Yes"},
        { key: 2, value: "No", label: "No"},
    ];

    const optionsIsSuspension = [
        { key: 1, value: "Yes", label: "Yes"},
        { key: 2, value: "No", label: "No"},
    ];

    // error state
    const [ errorFrameNumber, seterrorFrameNumber ] = useState("");
    const [ errorGearedType, seterrorGearedType ] = useState("");
    const [ errorNoOfGeared, seterrorNoOfGeared ] = useState("Please enter no of gears");
    const [ errorHaveInvoiceOrNot, seterrorHaveInvoiceOrNot ] = useState("");
    const [ errorUploadInvoice, seterrorUploadInvoice ] = useState("Please upload invoice");
    const [ errorYear, seterrorYear ] = useState("");
    const [ errorMonth, seterrorMonth ] = useState("");
    const [ errorSuspension, seterrorSuspension ] = useState("");
    
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    useEffect(() => {
        dispatch(StartCommonLoader(false));
        dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        ErrorMessageFromReddux.API=""
        setGetCurrentPath(pathname);
    }, [pathname]);

    useEffect(() => {
        if(typesofbicycle !== undefined) {
            if(Object?.keys(typesofbicycle)?.length > 0) {
                setApplicationState({
                    ...ApplicationState,
                    GearedType: typesofbicycle?.GearedType,
                    NoOfGeared: typesofbicycle?.NoOfGeared,
                    FrameNumber: typesofbicycle?.FrameNumber,
                    DoHaveInvoice: typesofbicycle?.DoHaveInvoice,
                    invoiceImage: typesofbicycle?.invoiceImage,
                    invoiceImageName: typesofbicycle?.invoiceImageName,
                    DoHaveSuspension: typesofbicycle?.DoHaveSuspension,
                    YearsMonths: {
                        Year: (typesofbicycle?.YearsMonths?.Year === null && typesofbicycle?.YearsMonths?.Year === "") ? "" : new Date(typesofbicycle?.YearsMonths?.Year),
                        Month: (typesofbicycle?.YearsMonths?.Month === null && typesofbicycle?.YearsMonths?.Month === "") ? "" : new Date(typesofbicycle?.YearsMonths?.Month),
                    },
                });
                setImageDisplay({
                    name: typesofbicycle?.invoiceImageName
                });
                if(typesofbicycle?.invoiceImageName !== "") {
                    seterrorUploadInvoice("");
                };
                if(typesofbicycle?.NoOfGeared !== "") {
                    seterrorNoOfGeared("")
                };
            };
        };
    }, []);

    // select user case
    const SelectGearedType = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                GearedType: value,
                NoOfGeared: ""
            });
            seterrorGearedType("");
        } else {
            setApplicationState({
                ...ApplicationState,
                GearedType: "",
                NoOfGeared: ""
            });
            seterrorGearedType("Please select geared type");
        }
    };

    // Do Have Invoice
    const SelectDoHaveInvoice = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                DoHaveInvoice: value,
                invoiceImage: null,
                invoiceImageName: "",
            });
            seterrorHaveInvoiceOrNot("");
            if(value?.label === "No") {
                setImageDisplay(""); 
            };
        } else {
            setApplicationState({
                ...ApplicationState,
                DoHaveInvoice: "",
                invoiceImage: null,
                invoiceImageName: "",
            });
            setImageDisplay("");
            seterrorHaveInvoiceOrNot("Please select 'Yes' if you have an invoice, or 'No' if you don't have an invoice.");
        };
    };

    const SelectIsSuspension = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                DoHaveSuspension: value,
            });
            seterrorSuspension("");
        } else {
            setApplicationState({
                ...ApplicationState,
                DoHaveSuspension: "",
            });
            seterrorSuspension("Please select 'Yes' if you have an suspension, or 'No' if you don't have an suspension.");
        }
    };

    // valid check frame number
    const CheckValidFrameNumber = (e) => {
        const { value } = e.target;
        // Define the regex pattern to allow only valid characters (alphanumeric and space)
        const regex = /^[a-zA-Z0-9\s]*$/;

        if (regex.test(value)) {
            setApplicationState({ ...ApplicationState, FrameNumber: value?.trim() })
        };
    };

    // upload invoice
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(formData);
        if (fileresponse?.status === 200) {
            setApplicationState({ ...ApplicationState, invoiceImage: fileresponse?.id, invoiceImageName: files.name });
            setImageDisplay({
                name: files.name
            });
            setUploadFileLoader(false);
            seterrorUploadInvoice("");
        } else {
            setUploadFileLoader(false);
            seterrorUploadInvoice("Please upload invoice");
        };
    };

    // select years
    const SelectYear = (date, url) => {
        if(url === "Year") {
            setApplicationState({
                ...ApplicationState,
                YearsMonths: {
                    ...ApplicationState?.YearsMonths,
                    Year : date,
                    Month : ""
                },
            });
            if(date !== "") {
                seterrorYear("");
            } else {
                seterrorYear("Please select year");
            };
        } else {
            setApplicationState({
                ...ApplicationState,
                YearsMonths: {
                    ...ApplicationState?.YearsMonths,
                    Month : date
                },
            });
            if(date !== "") {
                seterrorMonth("");
            } else {
                seterrorMonth("Please select month");
            };
        };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setApplicationState({ ...ApplicationState, invoiceImage: "" });
        setImageDisplay("");
        seterrorUploadInvoice("Please upload invoice");
    };

    var customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        })
    };

    // sign in function
    const SubmitRegister = () => { 
        if(ApplicationState?.GearedType !== "" && ApplicationState?.FrameNumber !== "" && ApplicationState?.DoHaveInvoice !== "" && ApplicationState?.DoHaveSuspension !== "" && (ApplicationState?.YearsMonths?.Year !== "" && ApplicationState?.YearsMonths?.Year !== null) && (ApplicationState?.YearsMonths?.Month !== "" && ApplicationState?.YearsMonths?.Month !== null)) {
            if(ApplicationState?.GearedType?.label === "Geared") {
                if(ApplicationState?.NoOfGeared !== "") {
                    if(ApplicationState?.DoHaveInvoice?.label === "Yes") {
                        if(ApplicationState?.invoiceImage !== null && ApplicationState?.invoiceImage !== "") {
                            dispatch(GetTypesOfBicycles(ApplicationState));
                            Navigate(BIKE_CONDITIONS_URL);
                        } else {
                            if(ApplicationState?.invoiceImage === null || ApplicationState?.invoiceImage === "") {
                                seterrorUploadInvoice("Please upload invoice");
                            } else {
                                seterrorUploadInvoice("");
                            };
                        }
                    } else {
                        dispatch(GetTypesOfBicycles(ApplicationState));
                        Navigate(BIKE_CONDITIONS_URL);
                    };
                } else {
                    CheckValid(ApplicationState?.NoOfGeared, { type: 'NoOfGeared', error: seterrorNoOfGeared })
                    if(ApplicationState?.invoiceImage === null || ApplicationState?.invoiceImage === "") {
                        seterrorUploadInvoice("Please upload invoice");
                    } else {
                        seterrorUploadInvoice("");
                    };
                };
            } else {
                if(ApplicationState?.DoHaveInvoice?.label === "Yes") {
                    if(ApplicationState?.invoiceImage !== null && ApplicationState?.invoiceImage !== "") {
                        dispatch(GetTypesOfBicycles(ApplicationState));
                        Navigate(BIKE_CONDITIONS_URL);
                    } else {
                        if(ApplicationState?.invoiceImage === null || ApplicationState?.invoiceImage === "") {
                            seterrorUploadInvoice("Please upload invoice");
                        } else {
                            seterrorUploadInvoice("");
                        };
                    };
                } else {
                    dispatch(GetTypesOfBicycles(ApplicationState));
                    Navigate(BIKE_CONDITIONS_URL);
                }
            };
        } else {
            if (ApplicationState?.GearedType !== "") {
                seterrorGearedType("");
            } else {
                seterrorGearedType("Please select geared type");
            };
            if (ApplicationState?.DoHaveInvoice !== "") {
                seterrorHaveInvoiceOrNot("");
            } else {
                seterrorHaveInvoiceOrNot("Please select 'Yes' if you have an invoice, or 'No' if you don't have an invoice.");
            };
            if (ApplicationState?.DoHaveSuspension !== "") {
                seterrorSuspension("");
            } else {
                seterrorSuspension("Please select 'Yes' if you have an suspension, or 'No' if you don't have an suspension.");
            };
            if(ApplicationState?.YearsMonths?.Year !== "" && ApplicationState?.YearsMonths?.Year !== null) {
                seterrorYear("");
            } else {
                seterrorYear("Please select year");
            };
            if(ApplicationState?.YearsMonths?.Month !== "" && ApplicationState?.YearsMonths?.Month !== null) {
                seterrorMonth("");
            } else {
                seterrorMonth("Please select month");
            };
            CheckValid(ApplicationState?.FrameNumber.trim(), { type: "FrameNumber", error: seterrorFrameNumber })
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "purchase_add") {
            Navigate(BIKE_CONDITIONS_URL)
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct">
                <h4>Bike Purchase Details</h4>
                    <div className="formloginset">
                        <Form.Group className="mb-3" >
                            <Form.Label>Frame Number*</Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.FrameNumber}
                                onChange={(e) => CheckValidFrameNumber(e)}
                                onKeyUp={(e) => CheckValid(e?.target?.value.trim(), { type: "FrameNumber", error: seterrorFrameNumber })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                }}
                                placeholder="Enter Frame Number"
                            />
                            {errorFrameNumber !== "" && <span className="error_medotory">{errorFrameNumber}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Do you have an invoice copy?*</Form.Label>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.DoHaveInvoice}
                                    onChange={(e) => SelectDoHaveInvoice(e)}
                                    isClearable
                                    options={optionsInvoiceYesNo}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select"
                                />
                            </div>
                            {errorHaveInvoiceOrNot !== "" && <span className="error_medotory">{errorHaveInvoiceOrNot}</span>}
                        </Form.Group>
                        {ApplicationState?.DoHaveInvoice?.value === "Yes" && (<Form.Group className="mb-3" >
                            <Form.Label>Upload Invoice*</Form.Label>
                            {(ImageDisplay !== undefined && ImageDisplay !== "") ? (<>{
                                ImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                    <i className="bi bi-filetype-pdf"></i> {ImageDisplay?.name}
                                    <sup className="closeIcon" onClick={() => RemoveUploadImage()}></sup>
                                </div>) : (
                                    <div className="pdffileviewo">
                                        <span>{ImageDisplay?.name}</span>
                                        <sup className="closeIcon" onClick={() => RemoveUploadImage()}></sup>
                                    </div>)}
                            </>) : (
                                <div className="uploadfilewithupload">
                                <Form.Control type="file" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />
                                {UploadFileLoader && (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>)}
                            </div>)}
                            {errorUploadInvoice !== "" && <span className="error_medotory">{errorUploadInvoice}</span>}
                        </Form.Group>)}
                        <Form.Group className="mb-3" >
                            <Form.Label>Do you have an suspension?*</Form.Label>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.DoHaveSuspension}
                                    onChange={(e) => SelectIsSuspension(e)}
                                    isClearable
                                    options={optionsIsSuspension}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select"
                                />
                            </div>
                            {errorSuspension !== "" && <span className="error_medotory">{errorSuspension}</span>}
                        </Form.Group>
                    </div>
                    <div className="formloginset">
                        <Form.Group className="mb-3" >
                            <Form.Label>Type of Bicycle*</Form.Label>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.GearedType}
                                    onChange={(e) => SelectGearedType(e)}
                                    isClearable
                                    options={optionsTypesGear}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Geared"
                                />
                            </div>
                            {errorGearedType !== "" && <span className="error_medotory">{errorGearedType}</span>}
                        </Form.Group>
                        {ApplicationState?.GearedType?.value === "Geared" && (<Form.Group className="mb-3" >
                            <Form.Label>No. of gears*</Form.Label>
                            <Form.Control
                                type="number"
                                className="inputBoxs"
                                value={ApplicationState?.NoOfGeared}
                                onChange={(e) => setApplicationState({ ...ApplicationState, NoOfGeared: e.target.value.replace(/^0/, "") })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'NoOfGeared', error: seterrorNoOfGeared })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                min="0"
                                placeholder="Enter No. of gears"
                            />
                            {errorNoOfGeared !== "" && <span className="error_medotory">{errorNoOfGeared}</span>}
                        </Form.Group>)}
                        <Form.Group className="mb-3 TwocolsInOneRow" >
                            <Form.Label>Purchase year & month?*</Form.Label>
                            <Form.Group>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="dateYearcolumn">
                                            <DatePicker
                                                selected={ApplicationState?.YearsMonths?.Year}
                                                onChange={(date) => SelectYear(date, "Year")}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                yearItemNumber={6}
                                                placeholderText="Select Year"
                                                isClearable={ApplicationState?.YearsMonths?.Year === "" ? false : true}
                                            />
                                            {errorYear !== "" && <span className="error_medotory">{errorYear}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="dateYearcolumn dateYearcolumnMonthsss">
                                            <DatePicker
                                                selected={ApplicationState?.YearsMonths?.Month}
                                                onChange={(date) => SelectYear(date, "Month")}
                                                minDate={ApplicationState.YearsMonths.Year ? new Date(ApplicationState.YearsMonths.Year, 0) : null}
                                                maxDate={moment(new Date()).format("yy") === moment(ApplicationState?.YearsMonths?.Year).format("yy") ? new Date() : null} // Current date
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                dateFormat="MM"
                                                yearItemNumber={9}
                                                placeholderText="Select Month"
                                                isClearable={ApplicationState?.YearsMonths?.Month === "" ? false : true}
                                                disabled={ApplicationState.YearsMonths.Year === "" || ApplicationState.YearsMonths.Year === null}
                                            />
                                            {errorMonth !== "" && <span className="error_medotory">{errorMonth}</span>}
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                            <Button className="registerbtn" id="registerbtn" onClick={() => SubmitRegister()}>
                                Next
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default Typesofbicycle;