/* eslint-disable */
import React from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import PDFCustomeSpeedometer from "../../Common/PDFCustomeSpeedometer";
import BikeImage01 from "../../../assets/img/pdftohtml/offers_bike/1.png";
import BikeImage02 from "../../../assets/img/pdftohtml/offers_bike/2.png";
import BikeImage03 from "../../../assets/img/pdftohtml/offers_bike/3.png";
import BikeImage04 from "../../../assets/img/pdftohtml/offers_bike/4.png";

const ImagePreviewToHtml_Third = () => {
    const { bikeconditionFilter, refurbishedResponse, typesofbicycle } = useSelector((state) => state.adminReducers);

    // If the number of points is greater than 5, then only show 5 items in the bike conditions.
    // const remainingItems = refurbishedResponse?.points?.length > 5 ? refurbishedResponse.points?.filter(elz => elz?.Images !== undefined).slice(5) : [];

    const remainingItems = bikeconditionFilter?.length > 5 ? bikeconditionFilter?.filter(elz => elz?.Images !== undefined).slice(5) : [];

    const SpeedometerScoreWiseDetails = {
        0: {
            title: "Exchange Now",
            description: "Your cycle/e-cycle is in scrap condition. All components are non-functional or broken. Tyres are unusable, the frame is severely damaged, and brakes and gears do not work. The paintwork is beyond repair. E-cycle components like the motor, battery, and display are completely non-functional. Your cycle/e-cycle has a negligible resale value, equivalent to scrap. (edited)"
        },
        1: {
            title: "Exchange Now",
            description: "Your cycle/e-cycle is in scrap condition. All components are non-functional or broken. Tyres are unusable, the frame is severely damaged, and brakes and gears do not work. The paintwork is beyond repair. E-cycle components like the motor, battery, and display are completely non-functional. Your cycle/e-cycle has a negligible resale value, equivalent to scrap. (edited)"
        },
        2: {
            title: "Weathered highly",
            description: "Your cycle/e-cycle is in very poor condition with most components barely functional. Tyres need immediate replacement, the frame and rims are heavily damaged, and brakes and gears are non-functional. The paintwork is severely damaged. E-cycle components are non-functional or require immediate replacement. Your cycle/e-cycle has minimal resale value, close to scrap."
        },
        3: {
            title: "Adequately Loved",
            description: "Your cycle/e-cycle is in poor condition with most components showing extensive wear. Tyres are worn out, the frame and rims are damaged, and brakes and gears need significant work. The paintwork is heavily scratched and dented. E-cycle components are close to the end of their lifespan with the motor, battery, and display needing repair or replacement.Your cycle/e-cycle has a very low resale value."
        },
        4: {
            title: "Decently Used",
            description: "Your cycle/e-cycle shows heavy use with all components exhibiting significant wear. Tyres may need replacement soon, and the frame and rims have noticeable damage. Brakes and gears may require servicing. The paintwork has several scratches and dents. E-cycle components are ageing, with the motor, battery, and display possibly requiring repair.Your cycle/e-cycle has a low resale value."
        },
        5: {
            title: "First signs of wear",
            description: "Your cycle/e-cycle has noticeable wear and tear. Tyres, frame, wheels, and rims show significant use. Brakes and gears may need minor adjustments or maintenance. The paintwork has scratches and possibly small dents. E-cycle motor, battery, and display function but may need attention soon.Your cycle/e-cycle holds a moderate resale value."
        },
        6: {
            title: "Ageing Finely",
            description: "Your cycle/e-cycle shows moderate signs of use. Tyres, frame, wheels, and rims have visible wear but are still functional. Brakes and gears are operational with some wear. The paintwork may have scratches. E-cycle components like the motor, battery, and display are still functional but may show some signs of ageing. Your cycle/e-cycle has a fair resale value."
        },
        7: {
            title: "Maintained greatly",
            description: "Your cycle/e-cycle has visible signs of use but all components are still in very good condition. Tyres show some wear, and there may be minor scratches on the frame and paintwork. Brakes and gears are greatly functional. For e-cycles, the motor, battery, and display are in good condition with no performance issues. Your cycle/e-cycle has a good resale value with moderate depreciation."
        },
        8: {
            title: "Next to new",
            description: "Your cycle/e-cycle shows minor signs of use. Tyres, frame, wheels, rims, and other components are in great condition with minor cosmetic wear. Brakes and gears function smoothly. E-cycle motor, battery, and display work efficiently. Your cycle/e-cycle has a high resale value with minor depreciation."
        },
        9: {
            title: "Almost new",
            description: "Your cycle/e-cycle is nearly in mint condition with minimal signs of use. All components are in excellent working order with very slight cosmetic blemishes, if any. The motor, battery, and display for e-cycles are in optimal condition. Your cycle/e-cycle holds a high resale value, just below brand new."
        },
        10: {
            title: "New",
            description: "Your cycle/e-cycle is in mint condition, similar to new. All components including tyres, frame, wheels, rims, paintwork, brakes, gears, saddle, and handlebar show no signs of wear. For e-cycles, the motor, battery, and display are functioning perfectly, as if just out of the box. Your cycle/e-cycle holds the highest resale value with only the automatic yearly depreciation considered."
        },
    };

    return(<div className="image_preview_wrapper" id="contentToCapture_Third" >
        <div className="main_container">
            <div className="user_details_with_cycle" style={{ paddingTop: "0px" }}>
                {bikeconditionFilter?.length > 5 && (<div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <div className="bike_condtions_gallery" style={{ paddingTop: "10px" }}>
                        <div className="row">
                            {remainingItems?.filter((elz) => {
                                if(typesofbicycle?.DoHaveSuspension?.label === "No") {
                                    if(elz?.key !== "Suspension") {
                                        return elz;
                                    };
                                } else {
                                    return elz;
                                };
                            })?.map((elm, index) => {
                                return(<div className="col-md-6 col-sm-6" key={index}>
                                    <div className="bike_gallery" >
                                        <h5>
                                            {elm?.key}
                                            <div className="bike_gallery_range">
                                                <ul className="custom_progress_relative_range">
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li>8</li>
                                                    <li>9</li>
                                                    <li>10</li>
                                                </ul>
                                                <div className="custom_progress_relative">
                                                    <div 
                                                        className={parseInt(elm?.value) === 0 ? "custom_progress_relative_fill first_range" : parseInt(elm?.value) === 10 ? "custom_progress_relative_fill ten_range" : "custom_progress_relative_fill"} 
                                                        style={{
                                                            width:
                                                              parseInt(elm?.value) === 1
                                                                ? `${parseInt(elm?.value * 10)}%`
                                                                : parseInt(elm?.value) > 1 && parseInt(elm?.value) < 10
                                                                ? `${parseInt(elm?.value * 10) - 2}%`
                                                                : `${parseInt(elm?.value * 10)}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </h5>
                                        <div className="images_gallery_wrapper">
                                            <div className="row">
                                                {elm?.Images?.map((elm, index) => {
                                                    return(<div className="col-md-6 col-sm-6" key={index}>
                                                        <Image src={elm?.base64_encode} alt={elm?.data?.name} />
                                                    </div>)
                                                })}
                                                {elm?.Images?.length === 1 && (<div className="col-md-6 col-sm-6">
                                                    <div className="images_gallery_dummy">
                                                        <i className="bi bi-card-image"></i>
                                                        <span>You have only one image on the left.</span>
                                                    </div>
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>)}
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <h4>Inspection Score</h4>
                    <div className="speedtom_wrapper">
                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-md-6 col-sm-6">
                                <div className="Inspection_score_details">
                                    <h3>{SpeedometerScoreWiseDetails[refurbishedResponse?.score?.toString()?.split("")[0]]?.title}</h3>
                                    <p>{SpeedometerScoreWiseDetails[refurbishedResponse?.score?.toString()?.split("")[0]]?.description}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <PDFCustomeSpeedometer score={refurbishedResponse?.score} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cycle_wraps_main cycle_wraps_main_wrapper" style={{ boxShadow: "6px 6px 12px -5px #ccc", marginTop: "30px" }}>
                    <h4>Offers for you</h4>
                    <div className="offers_n_bike_sections">
                        <div className="row">
                            {refurbishedResponse?.brand_list?.map((elm, index) => {
                                return(<div className="col-md-4 col-sm-4" key={index}>
                                    <div className="offer_for_column">
                                        <div className="offer_for_column_header">
                                            <span>{elm?.key}</span>
                                            <h5>₹ {elm?.value}/- Off</h5>
                                        </div>
                                        <Image src={elm?.key === "Doodle" ? BikeImage03 : elm?.key === "TreX Pro" ? BikeImage01 : elm?.key === "Trex+" ? BikeImage01 : elm?.key === "Trex Air" ? BikeImage02 : elm?.key === "X-factor" ? BikeImage01 : BikeImage04} alt="X-Factor" />
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ImagePreviewToHtml_Third;