import React from 'react';
import { useDispatch } from 'react-redux';
import { GetBikeConditionsFilters } from '../../redux/actions/adminActions';

const RangeSlider = (props) => {
    const { CurrentBikeCondtions, BikeCondtions, setBikeCondtions } = props;
    const dispatch = useDispatch();

    // input range background color
    const getBackground = () => {
        const percentage = ((CurrentBikeCondtions?.value - 1) / 9) * 100;
        return {
        background: `linear-gradient(to right, #00FF7F, #004DAC ${percentage}%, #aaa ${percentage}%)`
        };
    };

    // update range value function
    const handleChange = (e, id) => {
        const UpdateValue = BikeCondtions?.map((elm) => {
            if(elm?.id === id) {
                return {
                    ...elm,
                    value: e.target.value,
                }
            } else 
            return elm;
        });
        dispatch(GetBikeConditionsFilters(UpdateValue));
        setBikeCondtions(UpdateValue);
    };

    return (
        <div className="range-container">
            <div className="range-numbers">
                {Array.from({ length: 10 }, (_, i) => (
                <div key={i}>{i + 1}</div>
                ))}
            </div>
            <input
                type="range"
                id="rangeInput"
                min="1"
                max="10"
                step="1"
                value={CurrentBikeCondtions?.value}
                className={`range-setps-${CurrentBikeCondtions?.value}`}
                onChange={(e) => handleChange(e, CurrentBikeCondtions?.id)}
                style={getBackground()}
            />
        </div>
    );
};

export default RangeSlider;
