import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getModalVerifyOpenClose } from "../../redux/actions/adminActions";

const VerifyModal = () => {
    const dispatch = useDispatch();

    return(<div className="verfy_Modal_wrapper">
        <i className="bi bi-check-circle-fill"></i>
        <h1>Verified!</h1>
        <p>You have successfully Verified the account.</p>
        <Button onClick={() => dispatch(getModalVerifyOpenClose(false))}>Close</Button>
    </div>)
}

export default VerifyModal;