/* eslint-disable */
import React from "react";
import { Button, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate } from "react-router-dom";
import { OFFER_URL } from "../Shared/constant";
import FranchiseHeader from "../Common/FranchiseHeader";
import CustomeSpeedometer from "./CustomeSpeedometer";

const DetailsUploadScore = (props) => {
    const { setGetCurrentPath } = props;
    const Navigate = useNavigate();
    const { refurbishedResponse } = useSelector((state) => state.adminReducers);

    // sign in function
    const SubmitRegister = () => { 
        Navigate(OFFER_URL);
    };

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct">
                    <h3>Details Upload Score</h3>
                    <p>Here is your Bicycle Inspection Report.</p>
                    <div className="formloginset">
                        <CustomeSpeedometer score={refurbishedResponse?.score === undefined ? 0 : refurbishedResponse?.score} />
                        <div className="bottom_header">Inspection Score</div>
                        <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                            <Button className="registerbtn" id="registerbtn" onClick={() => SubmitRegister()}>Submit</Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default DetailsUploadScore;