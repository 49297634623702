export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    GET_USER_ID: "GET_USER_ID",
    GET_DEVICE_ID: "GET_DEVICE_ID",
    SELF_DETAILS: "SELF_DETAILS",
    MAIN_NAVIGATION: "MAIN_NAVIGATION",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    CLEAR_FORM_SET_FUCT: "CLEAR_FORM_SET_FUCT",
    SET_STORE_OPTIONS_LIST:"SET_STORE_OPTIONS_LIST",
    SET_STORE_OPTIONS_LIST_CLEAR:"SET_STORE_OPTIONS_LIST_CLEAR",
    GET_BRAND_LIST: "GET_BRAND_LIST",
    IMAGE_STORED_REDUX: "IMAGE_STORED_REDUX",
    ADD_NEW_BRAND_LIST: "ADD_NEW_BRAND_LIST",
    EDIT_BRAND_LIST: "EDIT_BRAND_LIST",
    GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
    GET_CATEGORY_LIST:"GET_CATEGORY_LIST",
    ADD_CATEGORY_LIST:"ADD_CATEGORY_LIST",
    UPDATE_CATEGORY_LIST:"UPDATE_CATEGORY_LIST",
    ACTIVE_LIST_ITEM_STATUS:"ACTIVE_LIST_ITEM_STATUS",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    SET_MAGIC_CODE: "SET_MAGIC_CODE",
    GET_SUB_CATEGORY_LIST: "GET_SUB_CATEGORY_LIST",
    ADD_SUB_CATEGORY_LIST: "ADD_SUB_CATEGORY_LIST",
    UPDATE_SUB_CATEGORY_LIST: "UPDATE_SUB_CATEGORY_LIST",
    GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
    UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",
    GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
    GET_ALL_SUB_CATEGORY_LIST: "GET_ALL_SUB_CATEGORY_LIST",
    GET_CITY_LIST: "GET_CITY_LIST",
    ADD_CITY_LIST:"ADD_CITY_LIST",
    UPDATE_CITY_LIST:"UPDATE_CITY_LIST",
    GET_STATE_LIST: "GET_STATE_LIST",
    ADD_STATE_LIST: "ADD_STATE_LIST",
    GET_ALL_STATE_LIST: "GET_ALL_STATE_LIST",
    UPDATE_STATE_LIST:"UPDATE_STATE_LIST",
    GET_PIN_CODE_LIST: "GET_PIN_CODE_LIST",
    GET_ALL_CITY_LIST: "GET_ALL_CITY_LIST",
    GET_REGION_MASTER_LIST: "GET_REGION_MASTER_LIST",
    ADD_REGION_MASTER_LIST: "ADD_REGION_MASTER_LIST",
    UPDATE_REGION_MASTER_LIST: "UPDATE_REGION_MASTER_LIST",
    GET_USER_LIST: "GET_USER_LIST",
    GET_ALL_USER_LIST: "GET_ALL_USER_LIST",
    GET_ALL_REGION_LIST: "GET_ALL_REGION_LIST",
    ADD_USER_LIST: "ADD_USER_LIST",
    UPDATE_USER_LIST: "UPDATE_USER_LIST",
    GET_WAREHOUSE_LIST: "GET_WAREHOUSE_LIST",
    PINCODES_SEARCH_LIST: "PINCODES_SEARCH_LIST",
    COMMON_LOADER: "COMMON_LOADER",
    ERROR_MESSAGE_FROM_REDUX: "ERROR_MESSAGE_FROM_REDUX",
    ADD_WAREHOUSE_LIST: "ADD_WAREHOUSE_LIST",
    UPDATE_WAREHOUSE_LIST: "UPDATE_WAREHOUSE_LIST",
    GET_ALL_WAREHOUSE_LIST: "GET_ALL_WAREHOUSE_LIST",
    STORED_DETAIL_REPORT_LIST: "STORED_DETAIL_REPORT_LIST",
    SET_STORE_ACCESSORIES_REPORT: "SET_STORE_ACCESSORIES_REPORT",
    STORED_LEVEL_REPORT_LIST: "STORED_LEVEL_REPORT_LIST",
    FRANCHISE_TYPE_LIST: "FRANCHISE_TYPE_LIST",
    ADD_FRANCHISE_TYPE_LIST: "ADD_FRANCHISE_TYPE_LIST",
    UPDATE_FRANCHISE_TYPE_LIST: "UPDATE_FRANCHISE_TYPE_LIST",
    GET_ALL_FRANCHISE_TYPE_LIST_LIST: "GET_ALL_FRANCHISE_TYPE_LIST_LIST",
    GET_FRANCHISE_USER_TYPE_LIST: "GET_FRANCHISE_USER_TYPE_LIST",
    ADD_FRANCHISE_USER_TYPE_LIST: "ADD_FRANCHISE_USER_TYPE_LIST",
    UPDATE_FRANCHISE_USER_TYPE_LIST: "UPDATE_FRANCHISE_USER_TYPE_LIST",
    GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST: "GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST",
    GET_PRODUCT_GROUP_LIST: "GET_PRODUCT_GROUP_LIST",
    ADD_PRODUCT_GROUP_LIST: "ADD_PRODUCT_GROUP_LIST",
    UPDATE_PRODUCT_GROUP_LIST: "UPDATE_PRODUCT_GROUP_LIST",
    GET_ALL_PRODUCT_GROUP_LIST: "GET_ALL_PRODUCT_GROUP_LIST",
    FRANCHISE_ORDER_REPORT_LIST: "FRANCHISE_ORDER_REPORT_LIST",
    ORDER_DETAILS_REPORT: "ORDER_DETAILS_REPORT",
    SET_STORE_ACCESSORIES_LIST: "SET_STORE_ACCESSORIES_LIST",
    ORDER_PO_APPROVALS_ORDERS: "ORDER_PO_APPROVALS_ORDERS",
    ORDER_WAREHOUSE_APPROVALS_ORDERS: "ORDER_WAREHOUSE_APPROVALS_ORDERS",
    PENDING_FOR_ACCOUNT_ORDERS_LIST: "PENDING_FOR_ACCOUNT_ORDERS_LIST",
    PENDING_FOR_STORES_ORDERS_LIST: "PENDING_FOR_STORES_ORDERS_LIST",
    DISPATCH_LIST: "DISPATCH_LIST",
    ADD_DISPATCH_LIST: "ADD_DISPATCH_LIST",
    UPDATE_DISPATCH_LIST: "UPDATE_DISPATCH_LIST",
    GET_ALL_DISPATCH_LIST: "GET_ALL_DISPATCH_LIST",
    SET_STORE_COURIERS_LIST:"SET_STORE_COURIERS_LIST",
    SET_STORE_PENDING_DISPATCH_LIST: "SET_STORE_PENDING_DISPATCH_LIST",
    SET_STORE_PENDING_DELIVERY_LIST: "SET_STORE_PENDING_DELIVERY_LIST",
    SET_STORE_OPTION_COURIERS_LIST: "SET_STORE_OPTION_COURIERS_LIST",
    SET_STORE_DELIVERY_LIST: "SET_STORE_DELIVERY_LIST",
    SET_STORE_REJECTED_ORDER_LIST: "SET_STORE_REJECTED_ORDER_LIST",
    SET_STORE_MODAL_INVOICE_DETAILS:"SET_STORE_MODAL_INVOICE_DETAILS",
    SET_STORE_CANCELLED_ORDER_LIST:"SET_STORE_CANCELLED_ORDER_LIST",
    SET_STORE_INVOICE_LIST:"SET_STORE_INVOICE_LIST",
    SET_STORE_EINVOICE_SUCCESS_LIST:"SET_STORE_EINVOICE_SUCCESS_LIST",
    SET_STORE_EINVOICE_FAILURE_LIST:"SET_STORE_EINVOICE_FAILURE_LIST",
    GET_STOCK_INWARD_LIST:"GET_STOCK_INWARD_LIST",
    GET_BOX_LIST:"GET_BOX_LIST",
    GET_ALL_BOX_LIST:"GET_ALL_BOX_LIST",
    ADD_BOX_LIST:"ADD_BOX_LIST",
    UPDATE_BOX_LIST:"UPDATE_BOX_LIST",
    ADD_PURCHASE_LIST:"ADD_PURCHASE_LIST",
    COMMON_MAIN_LOADER: "COMMON_MAIN_LOADER",
    GET_ALL_FRAME_LIST:"GET_ALL_FRAME_LIST",
    GET_CUSTOMER_DETAILS:"GET_CUSTOMER_DETAILS",
    GET_TYPES_OF_BICYCLES:"GET_TYPES_OF_BICYCLES",
    GET_REFURBISHED_RESPONSE:"GET_REFURBISHED_RESPONSE",
    GET_BIKE_CONDITIONS_FILTER:"GET_BIKE_CONDITIONS_FILTER",
    GET_BRAND_REFURBISHED_LIST:"GET_BRAND_REFURBISHED_LIST",
    GET_POINT_REFURBISHED_LIST:"GET_POINT_REFURBISHED_LIST",
    GET_BIKE_CONDITIONS_STEPS:"GET_BIKE_CONDITIONS_STEPS",
    GET_INSECTION_VIDEOS:"GET_INSECTION_VIDEOS",
    GET_DEALERS_LOGIN_DETAILS: "GET_DEALERS_LOGIN_DETAILS",
    GET_MODAL_VERIFY_OPEN_CLOSE: "GET_MODAL_VERIFY_OPEN_CLOSE",
    GET_BIKE_PURCHASE_DATA: "GET_BIKE_PURCHASE_DATA",
    GET_DEALER_CUSTOMER_STATUS: "GET_DEALER_CUSTOMER_STATUS"
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const setStoreCouriersList = (status) => {
    return {
        type: ActionTypes.SET_STORE_COURIERS_LIST,
        payload: status,
    }
}
export const setStoreInvoiceList = (status) => {
    return {
        type: ActionTypes.SET_STORE_INVOICE_LIST,
        payload: status,
    }
}
export const setStoreEInvoiceSuccessList = (status) => {
    return {
        type: ActionTypes.SET_STORE_EINVOICE_SUCCESS_LIST,
        payload: status,
    }
}
export const setStoreEInvoiceFailureList = (status) => {
    return {
        type: ActionTypes.SET_STORE_EINVOICE_FAILURE_LIST,
        payload: status,
    }
}
export const setStoreOptionCouriersList = (status) => {
    return {
        type: ActionTypes.SET_STORE_OPTION_COURIERS_LIST,
        payload: status,
    }
}
export const setStorePendingDispatchList = (status) => {
    return {
        type: ActionTypes.SET_STORE_PENDING_DISPATCH_LIST,
        payload: status,
    }
}
export const setStorePendingDeliveryList = (status) => {
    return {
        type: ActionTypes.SET_STORE_PENDING_DELIVERY_LIST,
        payload: status,
    }
}
export const setStoreDeliveredList = (status) => {
    return {
        type: ActionTypes.SET_STORE_DELIVERY_LIST,
        payload: status,
    }
}
export const setStoreRejectedOrderList = (status) => {
    return {
        type: ActionTypes.SET_STORE_REJECTED_ORDER_LIST,
        payload: status,
    }
}
export const setStoreCancelledOrderList = (status) => {
    return {
        type: ActionTypes.SET_STORE_CANCELLED_ORDER_LIST,
        payload: status,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.GET_USER_ID,
        payload: id,
    }
}

export const getDeviceId = (id) => {
    return {
        type: ActionTypes.GET_DEVICE_ID,
        payload: id,
    }
}

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const SelectMainNavigation = (url) => {
    return {
        type: ActionTypes.MAIN_NAVIGATION,
        payload: url,
    }
}


export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const ClearFormSetFutios = (date_) => {
    return {
        type: ActionTypes.CLEAR_FORM_SET_FUCT,
        payload: date_,
    }
}

export const setStoreOptionList = (date_) => {
    return {
        type: ActionTypes.SET_STORE_OPTIONS_LIST,
        payload: date_,
    }
}

export const setStoreOptionListClear = (date_) => {
    return {
        type: ActionTypes.SET_STORE_OPTIONS_LIST_CLEAR,
        payload: date_,
    }
}

export const getBrandList = (date_) => {
    return {
        type: ActionTypes.GET_BRAND_LIST,
        payload: date_,
    }
}

export const addNewBrandList = (date_) => {
    return {
        type: ActionTypes.ADD_NEW_BRAND_LIST,
        payload: date_,
    }
}

export const editBrandList = (date_) => {
    return {
        type: ActionTypes.EDIT_BRAND_LIST,
        payload: date_,
    }
}

export const imageStoredRedux = (date_) => {
    return {
        type: ActionTypes.IMAGE_STORED_REDUX,
        payload: date_,
    }
}

export const getCategoryList = (list) => {
    return {
        type: ActionTypes.GET_CATEGORY_LIST,
        payload: list,
    }
}

export const addCategoryList = (list) => {
    return {
        type: ActionTypes.ADD_CATEGORY_LIST,
        payload: list,
    }
}

export const updateCategoryList = (list) => {
    return {
        type: ActionTypes.UPDATE_CATEGORY_LIST,
        payload: list,
    }
}

export const getSubCategoryList = (list) => {
    return {
        type: ActionTypes.GET_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const addSubCategoryList = (list) => {
    return {
        type: ActionTypes.ADD_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const updateSubCategoryList = (list) => {
    return {
        type: ActionTypes.UPDATE_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const setGetMagicCode = (payload)=>{
    return{
        type: ActionTypes.SET_MAGIC_CODE,
        payload: payload
    }
}

export const getAllBrandList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BRANDS_LIST,
        payload: list,
    }
}

export const getAllCategoryList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CATEGORY_LIST,
        payload: list,
    }
}

export const getAllSubCategoryList = (list) => {
    return {
        type: ActionTypes.GET_ALL_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const ActiveListitemStatus = (list) => {
    return {
        type: ActionTypes.ACTIVE_LIST_ITEM_STATUS,
        payload: list,
    }
}

export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}

export const getProductList = (list) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: list,
    }
}

export const addProductList = (list) => {
    return {
        type: ActionTypes.ADD_PRODUCT_LIST,
        payload: list,
    }
}

export const getAllProductList = (list) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_LIST,
        payload: list,
    }
}

export const updateProductList = (list) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_LIST,
        payload: list,
    }
}

export const getStateList = (list) => {
    return {
        type: ActionTypes.GET_STATE_LIST,
        payload: list,
    }
}

export const addStateList = (list) => {
    return {
        type: ActionTypes.ADD_STATE_LIST,
        payload: list,
    }
}

export const getAllStateList = (list) => {
    return {
        type: ActionTypes.GET_ALL_STATE_LIST,
        payload: list,
    }
}

export const updateStateList = (list) => {
    return {
        type: ActionTypes.UPDATE_STATE_LIST,
        payload: list,
    }
}

export const getCityList = (list) => {
    return {
        type: ActionTypes.GET_CITY_LIST,
        payload: list,
    }
}

export const getAllCityList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CITY_LIST,
        payload: list,
    }
}

export const addCityList = (list) => {
    return {
        type: ActionTypes.ADD_CITY_LIST,
        payload: list,
    }
}

export const updateCityList = (list) => {
    return {
        type: ActionTypes.UPDATE_CITY_LIST,
        payload: list,
    }
}

export const getPinCodeList = (list) => {
    return {
        type: ActionTypes.GET_PIN_CODE_LIST,
        payload: list,
    }
}

export const getRegionMasterList = (list) => {
    return {
        type: ActionTypes.GET_REGION_MASTER_LIST,
        payload: list,
    }
}

export const addRegionMasterList = (list) => {
    return {
        type: ActionTypes.ADD_REGION_MASTER_LIST,
        payload: list,
    }
}

export const updateRegionMasterList = (list) => {
    return {
        type: ActionTypes.UPDATE_REGION_MASTER_LIST,
        payload: list,
    }
}

export const getUserList = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST,
        payload: list,
    }
}

export const getAllUserList = (list) => {
    return {
        type: ActionTypes.GET_ALL_USER_LIST,
        payload: list,
    }
}

export const addUserList = (list) => {
    return {
        type: ActionTypes.ADD_USER_LIST,
        payload: list,
    }
}

export const updateUserList = (list) => {
    return {
        type: ActionTypes.UPDATE_USER_LIST,
        payload: list,
    }
}

export const getAllRegionList = (list) => {
    return {
        type: ActionTypes.GET_ALL_REGION_LIST,
        payload: list,
    }
}

export const getWareHouseList = (list) => {
    return {
        type: ActionTypes.GET_WAREHOUSE_LIST,
        payload: list,
    }
}

export const addWareHouseList = (list) => {
    return {
        type: ActionTypes.ADD_WAREHOUSE_LIST,
        payload: list,
    }
}

export const updateWareHouseList = (list) => {
    return {
        type: ActionTypes.UPDATE_WAREHOUSE_LIST,
        payload: list,
    }
}

export const getAllWareHouseList = (list) => {
    return {
        type: ActionTypes.GET_ALL_WAREHOUSE_LIST,
        payload: list,
    }
}

export const SearchPincodeList = (list) => {
    return {
        type: ActionTypes.PINCODES_SEARCH_LIST,
        payload: list,
    }
}

export const StartCommonLoader = (loader) => {
    return {
        type: ActionTypes.COMMON_LOADER,
        payload: loader,
    }
}

export const ErrorMessageFromRedux = (error) => {
    return {
        type: ActionTypes.ERROR_MESSAGE_FROM_REDUX,
        payload: error,
    }
}

export const StoredDetailReport = (list) => {
    return {
        type: ActionTypes.STORED_DETAIL_REPORT_LIST,
        payload: list,
    }
}
export const setStoreAccessoriesReport = (list) => {
    return {
        type: ActionTypes.SET_STORE_ACCESSORIES_REPORT,
        payload: list,
    }
}

export const StoredLevelReport = (list) => {
    return {
        type: ActionTypes.STORED_LEVEL_REPORT_LIST,
        payload: list,
    }
}

export const GetFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const addFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const updateFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.UPDATE_FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const GetFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.GET_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const GetAllDealerFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const addFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const updateFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.UPDATE_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const GetAllFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.GET_ALL_FRANCHISE_TYPE_LIST_LIST,
        payload: list,
    }
}

export const GetProductGroupList = (list) => {
    return {
        type: ActionTypes.GET_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const GetAllProductGroupList = (list) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const addProductGroupList = (list) => {
    return {
        type: ActionTypes.ADD_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const updateProductGroupList = (list) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const FranchiseOrdersReportList = (list) => {
    return {
        type: ActionTypes.FRANCHISE_ORDER_REPORT_LIST,
        payload: list,
    }
}

export const GetOrderReportDetails = (details) => {
    return {
        type: ActionTypes.ORDER_DETAILS_REPORT,
        payload: details,
    }
}
export const setStoreAccessoriesList = (details) => {
    return {
        type: ActionTypes.SET_STORE_ACCESSORIES_LIST,
        payload: details,
    }
}
export const GetPOApprovalsOrderList = (list) => {
    return {
        type: ActionTypes.ORDER_PO_APPROVALS_ORDERS,
        payload: list,
    }
}

export const GetWareHouseApprovalsOrderList = (list) => {
    return {
        type: ActionTypes.ORDER_WAREHOUSE_APPROVALS_ORDERS,
        payload: list,
    }
}

export const PendingForAccountsOrderList = (list) => {
    return {
        type: ActionTypes.PENDING_FOR_ACCOUNT_ORDERS_LIST,
        payload: list,
    }
}

export const PendingForStoresOrderList = (list) => {
    return {
        type: ActionTypes.PENDING_FOR_STORES_ORDERS_LIST,
        payload: list,
    }
}

export const GetDispatchList = (list) => {
    return {
        type: ActionTypes.DISPATCH_LIST,
        payload: list,
    }
}

export const addDispatchList = (list) => {
    return {
        type: ActionTypes.ADD_DISPATCH_LIST,
        payload: list,
    }
}

export const updateDispatchList = (list) => {
    return {
        type: ActionTypes.UPDATE_DISPATCH_LIST,
        payload: list,
    }
}

export const GetAllDispatchList = (list) => {
    return {
        type: ActionTypes.GET_ALL_DISPATCH_LIST,
        payload: list,
    }
}

export const GetStockInwardList = (list) => {
    return {
        type: ActionTypes.GET_STOCK_INWARD_LIST,
        payload: list,
    }
}

export const setStoreModalInvoiceDetails = (list) => {
    return {
        type: ActionTypes.SET_STORE_MODAL_INVOICE_DETAILS,
        payload: list,
    }
}

export const getBoxList = (list) => {
    return {
        type: ActionTypes.GET_BOX_LIST,
        payload: list,
    }
}

export const addBoxList = (list) => {
    return {
        type: ActionTypes.ADD_BOX_LIST,
        payload: list,
    }
}

export const getAllBoxList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BOX_LIST,
        payload: list,
    }
}

export const updateBoxList = (list) => {
    return {
        type: ActionTypes.UPDATE_BOX_LIST,
        payload: list,
    }
}

export const addPurchaseList = (list) => {
    return {
        type: ActionTypes.ADD_PURCHASE_LIST,
        payload: list,
    }
}

export const GetFrameList = (list) => {
    return {
        type: ActionTypes.GET_ALL_FRAME_LIST,
        payload: list,
    }
}

export const StartMainCommonLoader = (loaderData_) => {
    return {
        type: ActionTypes.COMMON_MAIN_LOADER,
        payload: loaderData_,
    }
}

export const GetCustomerDetails = (data) => {
    return {
        type: ActionTypes.GET_CUSTOMER_DETAILS,
        payload: data,
    }
}

export const GetBikePurchaseDate = (data) => {
    return {
        type: ActionTypes.GET_BIKE_PURCHASE_DATA,
        payload: data,
    }
}

export const GetTypesOfBicycles = (data) => {
    return {
        type: ActionTypes.GET_TYPES_OF_BICYCLES,
        payload: data,
    }
}

export const GetBikeConditionsFilters = (data) => {
    return {
        type: ActionTypes.GET_BIKE_CONDITIONS_FILTER,
        payload: data,
    }
}

export const getRefurbishedResponse = (data) => {
    return {
        type: ActionTypes.GET_REFURBISHED_RESPONSE,
        payload: data,
    }
}

export const getRefurbishedBrandList = (list) => {
    return {
        type: ActionTypes.GET_BRAND_REFURBISHED_LIST,
        payload: list,
    }
}

export const getRefurbishedPointList = (list) => {
    return {
        type: ActionTypes.GET_POINT_REFURBISHED_LIST,
        payload: list,
    }
}

export const getBikeCondtionsSteps = (steps) => {
    return {
        type: ActionTypes.GET_BIKE_CONDITIONS_STEPS,
        payload: steps
    }
}

export const getInspectionVideos = (videos) => {
    return {
        type: ActionTypes.GET_INSECTION_VIDEOS,
        payload: videos
    }
}

export const getDealersLoginDetails = (login) => {
    return {
        type: ActionTypes.GET_DEALERS_LOGIN_DETAILS,
        payload: login
    }
}

export const getModalVerifyOpenClose = (modal) => {
    return {
        type: ActionTypes.GET_MODAL_VERIFY_OPEN_CLOSE,
        payload: modal
    }
}

export const getDealerCustomerStatus = (status) => {
    return {
        type: ActionTypes.GET_DEALER_CUSTOMER_STATUS,
        payload: status
    }
}