/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Image, Spinner } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import GuestLayout from "../Shared/GuestLayout";
import { useDispatch, useSelector } from "react-redux";
import { StartMainCommonLoader, getDeviceId, getDealersLoginDetails, getDealerCustomerStatus } from "../../redux/actions/adminActions";
import 'react-toastify/dist/ReactToastify.css';
import BackgroundImage1 from "../../assets/img/login_background_01.webp";
import BackgroundImage2 from "../../assets/img/login_background_02.webp";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import FranchiseHeader from "../Common/FranchiseHeader";
import { useNavigate,  } from "react-router-dom";
import { SELECT_SELLER_URL } from "../Shared/constant";

const Login = () => {
    const [ email, setEmail ] = useState("");
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const dispatch = useDispatch();
    const { device_id, commonMainLoader, dealers_login_details } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();

    // generate unique id
    function uuidv4() { 
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    
    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorOtp, setErrorOtp ] = useState("");

    // on enter login
    const onEnterLogin = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SignInButton();
        };
    };

    const handleChange = (element, index) => {
        const value = element.value.replace(/[^0-9]/g, ''); // Allow only digits
        if (value) {
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
    
          // Move to the next input field
          if (element.nextSibling) {
            element.nextSibling.focus();
          }
        } else {
        const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
        }
    };
    
    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index !== 0) {
            // Move to the previous input field
            event.target.previousSibling.focus();
        }
    };
    
    // otp send function
    const SignInButton = async () => {
        if(email !== "") {
            if(EmailRegex.test(email) === true) {
                var DeviceID = uuidv4();
                dispatch(getDeviceId(DeviceID));
                dispatch(StartMainCommonLoader({
                    url: "franchise_send_otp",
                    loader: true,
                }))
                let param = { 
                    "transmit": "single", 
                    "url": "franchise_send_otp",
                    "request" :  { 
                        "email" : email, 
                    },
                    "DeviceId" : DeviceID,
                };
                wsSend_request(websocket, param);
            } else {
                CheckValid(email, {type: 'email', error: setErrorEmail });
            }
        } else {
            CheckValid(email, {type: 'email', error: setErrorEmail });
        };
    };

    // sign in function
    const SignInFrnchiseButton = () => {
        if(otp?.toString()?.replaceAll(",","") !== "") {
            dispatch(StartMainCommonLoader({
                url: "franchise_login",
                loader: true,
            }))
            let param = { 
                "transmit": "single", 
                "url": "franchise_login",
                "request" :  { 
                    "email" : email, 
                    "otp" : otp?.toString()?.replaceAll(",",""),
                },
                "DeviceId" : device_id,
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(otp?.toString()?.replaceAll(",",""), {type: 'otp', error: setErrorOtp });
        };
    };

    useEffect(() => {
        if(Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length > 0) {
            setEmail(dealers_login_details?.email);
        }
    }, [ dealers_login_details ]);

    const Backbtn = () => {
        setErrorEmail("");
        setErrorOtp("");
        setOtp(['', '', '', '', '', '']);
        setEmail("");
        dispatch(getDealersLoginDetails({}));
    };

    // back to Permission Status
    const BackPermisionResellbtn = () => {
        Navigate(SELECT_SELLER_URL);
        dispatch(getDealerCustomerStatus({
            dealerExist: false,
            customerExist: false,
        }));
    };

    return(<GuestLayout>
        <div className="MainregisterproductWrapper MainLoginScreen" >
            <div className="main_form_Box">
                <FranchiseHeader logotype={"white"} />
                <div className="loginMainblog Mainregisterproduct">
                    {Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length === 0 ? (<h4>Login to your Dealer</h4>) : (<div className="verifyotpheader">
                        <h4>Verify your email</h4>
                        <p>Please enter the 6 digit code sent to {email}</p>
                    </div>)}
                    <div className="formloginset">
                        {Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length === 0 ? (<Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color: "#fff" }}>Email ID *</Form.Label>
                            <input type="email" className="d-none" />
                            <Form.Control type="email" value={email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => {onEnterLogin(e); EmptySpaceFieldValid(e)}} placeholder="Enter Email ID" autoComplete="new-email" disabled={(commonMainLoader?.url === "franchise_send_otp" && commonMainLoader?.loader) || Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length > 0} />
                            {errorEmail && (<div className="error_medotory" style={{ color: "#fff" }}>{errorEmail}</div>)}
                        </Form.Group>) :
                        (<Form.Group className="mb-3" controlId="formBasicEmail">
                            <div className="otp_wrappper" >
                                {otp.map((digit, index) => (
                                    <Form.Control
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    
                                    />
                                ))}
                            </div>
                            {/* {errorOtp && (<div className="error_medotory" style={{ color: "#fff" }}>{errorOtp}</div>)} */}
                        </Form.Group>)}

                        {Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length === 0 ? (<Button onClick={SignInButton} className="registerbtn btn btn-primary"  disabled={(commonMainLoader?.url === "franchise_send_otp" && commonMainLoader?.loader)}>
                            {(commonMainLoader?.url === "franchise_send_otp" && commonMainLoader?.loader) ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : "Send OTP"}
                        </Button>) : (<React.Fragment>
                            <button className="back_btn sendOpt_btn" onClick={() => SignInButton()}>
                                {(commonMainLoader?.url === "franchise_send_otp" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>)} 
                                <i className="bi bi-send"></i>Resend OTP
                            </button>
                            <Button onClick={SignInFrnchiseButton} className="registerbtn btn btn-primary"  disabled={(commonMainLoader?.url === "franchise_login" && commonMainLoader?.loader) || otp?.toString()?.replaceAll(",","")?.length < 6}>
                                {(commonMainLoader?.url === "franchise_login" && commonMainLoader?.loader) ? (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>) : "Confirm"}
                            </Button>
                            <button className="back_btn back_btn_transfernt" onClick={() => Backbtn()}>
                                <i className="bi bi-envelope-at-fill"></i>&nbsp; Change Email
                            </button>
                        </React.Fragment>)}

                        {Object?.keys(dealers_login_details === undefined ? {} : dealers_login_details)?.length === 0 && (<button className="back_btn" onClick={() => BackPermisionResellbtn()}>
                            <i className="bi bi-arrow-left-short"></i> Back
                        </button>)}
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default Login;