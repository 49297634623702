import React from "react";
import ScaleImage from "../../assets/img/time_scale.png";
import { Image } from "react-bootstrap";

const CustomeSpeedometer = (props) => {
    const { score } = props;

    return(<div className="Speedometer_wrapper">
        <div 
            role="progressbar"
            aria-valuenow={75}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ '--value': score }}
        ></div>
        <div className="labels">
            <Image src={ScaleImage} alt="time" />
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <div className="mainscore_title" dangerouslySetInnerHTML={{ __html: score }} ></div>
    </div>)
}

export default CustomeSpeedometer;