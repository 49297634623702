/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form,Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import CommonToggle from "../Common/CommonToggle";
import {useParams} from 'react-router-dom'

const CommonewModel = (props) => {
    const Dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const { websocket } = useContext(WebSocketContext);
    const { showModalNew, setShowModalNew, ConfirmOrder} = props;
    const { accessToken, device_id, boxList} = useSelector((state) => state.adminReducers);
    const [ CreateBoxState, setCreateBoxState ] = useState({
        box_name : "", 
        box_height : "", 
        box_width : "", 
        box_length : "", 
        is_active : true
    })
    const exceptThisCostSymbols = ["e", "E", "+", "-"];
    const boxData = boxList?.list?.filter((elm) => elm?.id === showModalNew?.Data?.id)[0];

    // error state
    const [ errorbox_name, setErrorbox_name ] = useState("");
    const [ errorbox_height, setErrorbox_height ] = useState("");
    const [ errorbox_width, setErrorbox_width ] = useState("");
    const [ errorbox_length, setErrorbox_length ] = useState("");
    const { roomId } = useParams();
    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };
    
    useEffect(() => {
        if(showModalNew?.subtitle === "Update New Box") {
            setCreateBoxState({
                ...CreateBoxState,
                box_name:boxData?.box_name,
                box_height:boxData?.box_height,
                box_width:boxData?.box_width,
                box_length:boxData?.box_length,
                is_active: boxData?.is_active,
            });
        };
    }, [ boxData ])

    const CommonFunction = async () => {
        if(showModalNew?.subtitle === "Logout") {
            let param = { 
                "transmit": "single", 
                "url": "franchise_logout",
                "request" :  { 
                    "token" : accessToken, 
                },
                "DeviceId" : device_id,
            };
            wsSend_request(websocket, param);
        };
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<Modal className={showModalNew?.title === "Create Supplier" ? "commonModal addSupplierModel" : (showModalNew?.title === "Create New Box" || showModalNew?.title === "Update New Box") ? "commonModal addBoxModalNew" : "commonModal"} show={showModalNew?.open} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : ""}}>{showModalNew?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="notformsection">
                {showModalNew?.subtitle === "Logout" && (<i className="bi bi-box-arrow-right logoutIconbtn"></i>)}
                <p dangerouslySetInnerHTML={{__html: showModalNew?.description}}></p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button disabled={loader} variant="primary" className="addbtncmn" onClick={CommonFunction}>
                {showModalNew?.button}
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default CommonewModel;